import React, { useState } from "react";
import { TextField, Button, Grid, MenuItem, Select } from "@mui/material";
import axios from "axios"; // Import Axios
import { useNavigate } from "react-router-dom";
const CreateFaclityProfile = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = localStorage.getItem("auth-token");
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    mobileNumber: "",
    email: "",
    image: "",
    joiningDate: "",
    linkedInId: "", // Corrected linkedInId spelling
    githubId: "", // Corrected githubId spelling
    about: "",
    dateOfBirth: "",
    experience: "",
  });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleFileChange = (e) => {
  //   setFormData({ ...formData, image: e.target.files[0] }); // Corrected photo spelling
  // };

  const handleSelectChange = (e) => {
    // Define handleSelectChange function
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [previewImage, setPreviewImage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("mobileNumber", formData.mobileNumber);
      formDataToSend.append("position", formData.position);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("joiningDate", formData.joiningDate);
      formDataToSend.append("githubId", formData.githubId);
      formDataToSend.append("linkedInId", formData.linkedInId);
      formDataToSend.append("about", formData.about);
      formDataToSend.append("dateOfBirth", formData.dateOfBirth);
      formDataToSend.append("experience", formData.experience);
      formDataToSend.append("image", formData.image);

      const response = await axios.post(
        `${apiUrl}/faclity/create-faclity`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": authToken,
          },
        }
      );
      setFormData({
        name: "",
        position: "",
        mobileNumber: "",
        email: "",
        image: "",
        joiningDate: "",
        linkedInId: "",
        githubId: "",
        about: "",
        dateOfBirth: "",
        experience: "",
      });
      setPreviewImage(null);
      navigate("/faclity-profile");
      console.log("Employee created:", response.data);
    } catch (error) {
      console.error("Error creating employee:", error);
    }
  };

  return (
    <>
      <div
        className="maincontainer-facliy"
        style={{
          padding: "22px",
          backgroundColor: "white",
          margin: "13px",
          borderRadius: "7px",
        }}
      >
        <h2 style={{ textAlign: "center", margin: "3px" }}>
          Create Faclity Profile
        </h2>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="name"
                label="Name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="position"
                label="Position"
                value={formData.position}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="mobileNumber"
                label="Mobile Number"
                value={formData.mobileNumber}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="email"
                label="Email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            {/* <Grid item xs={6}>
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
              {previewImage && (
                <img
                  src={previewImage}
                  alt="Preview"
                  style={{ maxWidth: "100%", marginTop: "10px" }}
                />
              )}
            </Grid> */}
            <Grid item xs={6}>
              <TextField
                name="image"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="githubId"
                label="GitHub ID"
                value={formData.githubId}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="joiningDate"
                label="Jonning Date"
                type="date"
                value={formData.joiningDate}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="linkedInId"
                label="LinkedIn ID"
                value={formData.linkedInId}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid container spacing={2} style={{ paddingLeft: "12px" }}>
              <Grid item xs={6}>
                <TextField
                  name="dateOfBirth"
                  label="Date of Birth"
                  type="date"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: "15px" }}>
                <Select
                  name="experience"
                  value={formData.experience}
                  onChange={handleSelectChange}
                  fullWidth
                  margin="normal"
                  displayEmpty
                  inputProps={{ "aria-label": "Select Experience" }}
                >
                  <MenuItem value="" disabled>
                    Select Experience
                  </MenuItem>
                  <MenuItem value="Fresher">Fresher</MenuItem>
                  <MenuItem value="6months-1year">6 months to 1 year</MenuItem>
                  <MenuItem value="1year-2years">1year-2years</MenuItem>
                  <MenuItem value="2year-3years">2year-3years</MenuItem>
                  <MenuItem value="3year-4years">3year-4years</MenuItem>
                  <MenuItem value="5years-above">5 years and above</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="about"
                label="About"
                value={formData.about}
                onChange={handleChange}
                // multiline
                // rows={4}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>

          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};

export default CreateFaclityProfile;

// export default CreateFaclityProfile;
