import React, { useState, useEffect } from "react";
import "./dasboard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { NavLink } from "react-router-dom";
import {LogoutOutlined} from "@ant-design/icons"
// import { useAuth } from "../context/AuthContext";
const Dasboard = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = localStorage.getItem("auth-token");
  const [inquiryData, setInquiryData] = useState(null);
  const [userName, setUserName] = useState(null);
  const [admissionData, setAdmissionData] = useState({ totalDownPayment: 0 });
  const [invoiceData, setInvoiceData] = useState({ totalAmount: 0 });
  const [error, setError] = useState(null);

  const handleStartDateChange = (event) => {
    // setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    // setEndDate(event.target.value);
  };

  const fetchData = async (url, setDataFunction) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      setDataFunction(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error); // Log the error
      setError(error.message);
    }
  };

  useEffect(() => {
   
    fetchData(`${apiUrl}/inquiry/get-inquri`, setInquiryData);
    fetchData(`${apiUrl}/student/get-student`, setAdmissionData);
    fetchData(`${apiUrl}/invoice/get-invoice/total-amount`, setInvoiceData);
  }, []);

  const handleSearch = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/inquiry/search`,
        // { startDate, endDate },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      // Handle response data here
      console.log(response.data);
    } catch (error) {
      console.error("Error searching data:", error);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!inquiryData || !admissionData || !invoiceData) {
        // Navigate to home page if data fetching fails after 10 seconds
        navigate("/");
      }
    }, 6000);

    return () => clearTimeout(timeoutId); // Clear timeout on component unmount
  }, [inquiryData, admissionData, invoiceData, navigate]);

  // Calculate total amount
 
  const handleLogout = () => {
    // Clear authentication token from local storage
    localStorage.removeItem("auth-token");
    // Redirect to home page
    navigate("/");
  };

  return (
    <div>
      <div className="centerdasboard">
        <div
          className="centerh3"
          style={{ display: "flex", textAlign: "center", alignItems: "center" }}
        >
          <img
            src={require("../../assest/miracleitlogowebp.webp")}
            alt="Description of the image"
            style={{ width: "55px" }}
          />
          <h5 style={{ fontSize: "25px", letterSpacing: "2px",marginLeft:"20px"}}>
            Miracle Career IT Academy Center's Dashboard
          </h5>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>

            {/* <p style={{ color: "white" }}>Center Name: {userName}</p> */}
          
          <LogoutOutlined
            style={{
              fontSize: "22px",
              width: 35,
              height: 35,
              cursor: "pointer",
              transform: "rotate(-88deg)",
              borderRadius: "50px",
              backgroundColor: "#8b8bc6",
              marginLeft: "20px",
              padding: "6px",
              color: "white",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
            onClick={handleLogout}
          />
        </div>
      </div>
      <div className="row mt-4 ceanterboardmain">
        <div className="col-sm-5">
          <div
            className="card dacrboadcard"
            style={{ height: "27rem", cursor: "pointer" }}
          >
            <div className="card-body">
              <h5 className="thistitel">Notification</h5>
              <hr />
              <div id="scroll-container">
                <div id="scroll-text">
                  {/* {notifications.map((notification, index) => (
                    <div key={index} className="notification-item">
                      <button className="arrow-button">
                        <FontAwesomeIcon icon={faArrowRight} />
                      </button>
                      <div className="notification-message">
                        {notification.message}
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-7 padding7">
          <div className="row">
            <div className="col-sm-4 mt-2">
              <div className="card dacrboadcard">
                <NavLink to="/home">
                  {" "}
                  <div className="card-body erpcardbody">
                    <img
                      src={require("../../assest/erp1.png")}
                      className="erpitems"
                      alt="Miracle IT Career"
                    />
                    <h5 className="card-title titelcard">ERP</h5>
                  </div>{" "}
                </NavLink>
              </div>
            </div>
            <div className="col-sm-4  mt-2">
              <div className="card dacrboadcard">
                <div className="card-body erpcardbody">
                  <img
                    src={require("../../assest/erp2.png")}
                    className="erpitems"
                    alt="Miracle IT Career"
                  />
                  <h5 className="card-title titelcard">Reports</h5>
                </div>
              </div>
            </div>
            <div className="col-sm-4  mt-2">
              <div className="card dacrboadcard">
                <div className="card-body erpcardbody">
                  <img
                    src={require("../../assest/erp3.png")}
                    className="erpitemslms"
                    alt="Miracle IT Career"
                  />
                  <h5 className="card-title titelcard">LMS</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="card dacrboadcard">
                <NavLink to="/inquri-data">
                  <div className="card-body erpcardbody">
                    <img
                      src={require("../../assest/erp4.png")}
                      className="erpitems"
                      alt="Miracle IT Career"
                    />
                    <h5 className="card-title titelcard">Inquiry</h5>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card dacrboadcard">
                <div className="card-body erpcardbody">
                  <img
                    src={require("../../assest/erp6.png")}
                    className="erpitems"
                    alt="Miracle IT Career"
                  />
                  <h5 className="card-title titelcard">Indent</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6  mb-1">
              <div className="card dacrboadcard">
                <NavLink to="/faclity-profile">
                  <div className="card-body erpcardbody">
                    <img
                      src={require("../../assest/erp5.png")}
                      className="erpitems"
                      alt="Miracle IT Career"
                    />

                    <h5 className="card-title titelcard">Employee </h5>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="col-sm-6 mb-1">
              <div className="card dacrboadcard">
                <div className="card-body erpcardbody">
                  <img
                    src={require("../../assest/erp7.png")}
                    className="erpitems itemserp1"
                    alt="Miracle IT Career"
                  />
                  <h5 className="card-title titelcard">Pelacement</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dasboard;
