import React from "react";
import CreateAdmission from "../component/admission/CreateAamission";
import Navbar from "../component/navbar/Navbar";
const Admission = () => {
  return (
    <>
      <Navbar>
        <CreateAdmission></CreateAdmission>
      </Navbar>
    </>
  );
};

export default Admission;
