import React, { useEffect, useState } from "react";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Actions from "../utilites/Action";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../lodingpage/LoadingPage";

const ProfileCardGrid = () => {
  const buttonStyle = {
    width: "200px",
    margin: "auto",
    display: "block",
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading
  const authToken = localStorage.getItem("auth-token");
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/faclity/get-faclity`, {
        headers: {
          "auth-token": authToken,
        },
      });
      setData(response.data);
      console.log(response.data)
      setLoading(false); // Update loading state when data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    return;
  }, [authToken]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/faclity/delete-faclity/${id}`, {
        headers: {
          "auth-token": authToken,
        },
      });
      fetchData();
    } catch (error) {
      console.error("Error deleting faculty member:", error);
    }
  };

  const handleUpdate = async (id) => {
    console.log("Update faculty with id:", id);
  };

  if (loading) {
    return <LoadingSpinner />; // Render loading indicator
  }

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <Link to="/create-faclity-profile">
          <Button variant="contained" style={buttonStyle}>
            Add Faculty
          </Button>
        </Link>
      </div>
      <h2 style={{ textAlign: "center", marginTop: "10px" }}>
        Our Faculty Profile
      </h2>
      {data.map((item) => (
        <div
          key={item.id}
          className="p-6 m-2 sm:p-2 bg-white dark:bg-gray-900 dark:text-gray-100"
        >
          <div
            className="flex flex-col space-y-4 md:space-y-0 md:space-x-6 md:flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <img
              src={`${apiUrl}${item.image}`}
              alt="User Avatar"
              className="self-center flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start dark:bg-gray-500 dark:border-gray-700"
            />
            <div className="flex flex-col">
              <h1 className="font-bold text-center text-3xl text-gray-900">
                {item.name}
              </h1>
              <p className="text-center text-sm text-gray-400 font-medium">
                {item.position}
              </p>
              <p className="dark:text-gray-400">{item.about}</p>
              <div
                className="flex justify-center pt-2 space-x-4 align-center"
                style={{ cursor: "pointer" }}
              >
                <a href={`mailto:${item.email}`}>
                  <ContactMailIcon />
                </a>
                <a href={item.githubId}>
                  <GitHubIcon />
                </a>
                <a href={item.linkedInId}>
                  <LinkedInIcon />
                </a>
              </div>
            </div>
            <div className="flex justify-center pt-2 space-x-4 align-center">
              <Actions
                onDelete={() => handleDelete(item._id)}
                onUpdate={() => handleUpdate(item._id)}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProfileCardGrid;
