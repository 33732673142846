import React, { useState, useEffect } from "react";
import styled from "styled-components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { DatePicker, Select } from "antd";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../lodingpage/LoadingPage";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Container = styled.div`
  height: 288px;
  overflow: scroll;
  position: relative;
`;

const Table = styled.table`
  border: 0.01px solid rgba(0, 0, 0, 0.272);
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const TableCell = styled.td`
  border-bottom: 0.01px solid rgba(67, 66, 66, 0.636);
  height: 30px;
  padding: 8px 20px;
  font-size: 13px;
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconCell = styled.td`
  text-align: center;
`;

const ModalContainer = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid #888;
  width: 33%;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;

const AdmissionData = () => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("auth-token");
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [filterType, setFilterType] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [error, setError] = useState(null);
  const [totalAdmission, setTotalAdmission] = useState({
    success: false,
    countTotal: 0,
    message: "",
    admission: [],
    totalDownPayment: 0,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = (admission) => {
    setSelectedRow(admission);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedRow(null);
    setModalOpen(false);
  };

  const fetchData = async () => {
    try {
      if (!authToken) {
        console.error("No auth token found.");
        return;
      }

      const response = await axios.get(`${apiUrl}/student/get-student`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      setTotalAdmission(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFilter = async () => {
    try {
      let startDate = "";
      let endDate = "";
      if (dateRange.length === 2) {
        startDate = dateRange[0].format("YYYY-MM-DD");
        endDate = dateRange[1].format("YYYY-MM-DD");
      } else {
        setError("Please select a date range.");
        return;
      }
      
      let url = "";
      switch (filterType) {
        case "Date":
          url = `${apiUrl}/student/admission/betweenDates/${startDate}/${endDate}`;
          break;
        case "Career Course":
        case "Module Course":
        case "Internship":
          url = `${apiUrl}/student/admission/betweenDatesBySource/${startDate}/${endDate}/${filterType}`;
          break;
        default:
          return;
      }
      
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      
      setTotalAdmission({
        ...totalAdmission,
        success: response.data.success,
        totalCount: response.data.countTotal,
        message: response.data.message,
        admission: response.data.admission,
      });
      
      setError(null); // Reset error state
    } catch (error) {
      console.error("Error filtering data:", error);
      setError(error.response?.data?.message || "Something went wrong.");
    }
  };

  const handleUpdateProfile = () => {
    if (selectedRow) {
      // Navigate using the ID of the selected admission
      navigate(`/create-admission/${selectedRow._id}`);
    } else {
      console.error("No Admission selected.");
    }
  };

  const handleViewProfile = () => {
    if (selectedRow) {
      // Navigate using the ID of the selected admission
      navigate(`/single-student/${selectedRow._id}`);
    } else {
      console.error("No Admission selected.");
    }
  };

  const handlePayment = () => {
    if (selectedRow) {
      // Navigate using the ID of the selected admission
      navigate(`/create-fees-recepit/${selectedRow._id}`);
    } else {
      console.error("No Admission selected.");
    }
  };

  if (!totalAdmission.success) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="col-sm-12 mb-3 mb-sm-0" style={{ margin: "auto", width: "96%", marginTop: "20px" }}>
        <div className="card w-100">
          <div className="card-body" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h4 className="card-title" style={{ fontSize: "25px" }}>Admission List</h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon style={{ fontSize: "15px", marginLeft: "13px" }} />
              Admission
            </h6>
          </div>
        </div>
      </div>
      <div className="col-sm-12 mb-3 mb-sm-0" style={{ margin: "auto", width: "96%", marginTop: "10px" }}>
        <div className="card w-100">
          <div className="card-body">
            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
              <div>
                <Select defaultValue="Select by Filter" style={{ marginLeft: "10px", textAlign: "justify", height: "39px" }} onChange={(value) => setFilterType(value)}>
                  <Option value="Date">Date</Option>
                  <Option value="Career Course">Career Course</Option>
                  <Option value="Module Course">Module Course</Option>
                  <Option value="Internship">Internship</Option>
                </Select>
              </div>
              <div style={{ marginLeft: "10px" }}>
                Date Range
                <RangePicker style={{ marginLeft: "20px" }} onChange={(dates) => setDateRange(dates)} />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Button type="primary" onClick={handleFilter}>Search</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link to="/create-admission">
        <Button style={{ margin: "5px", backgroundColor: "#5151d7", width: "200px", color: "white" }}>Create Admission</Button>
      </Link>
      <Container>
        <Table>
          <tbody>
            <TableRow className="first">
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell>Student ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile No.</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Total Fees</TableCell>
              <TableCell>Counsellor</TableCell>
              <TableCell>Course Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Course Duration</TableCell>
              <IconCell />
            </TableRow>
            {totalAdmission.admission && totalAdmission.admission.map((admission, index) => (
              <TableRow key={index}>
                <TableCell>
                  <RemoveRedEyeIcon style={{ cursor: "pointer" }} onClick={() => openModal(admission)} />
                </TableCell>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{admission.studentid}</TableCell>
                <TableCell>{admission.name}</TableCell>
                <TableCell>{admission.email}</TableCell>
                <TableCell>{admission.mobileNumber}</TableCell>
                <TableCell>{admission.cource}</TableCell>
                <TableCell>{admission.finalFees}</TableCell>
                <TableCell>{admission.councellingBy}</TableCell>
                <TableCell>{admission.courceType}</TableCell>
                <TableCell>{moment(admission.admissionDate).format("DD/MM/YYYY")}</TableCell>
                <TableCell>{admission.durationCource}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
        <ModalContainer open={modalOpen} onClick={closeModal}>
          <ModalContent>
            <h2>Actions</h2>
            <Button onClick={handleUpdateProfile}>Update Profile</Button>
            <Button onClick={handleViewProfile}>View Profile</Button>
            <Button onClick={handlePayment}>Create Invoice</Button>
          </ModalContent>
        </ModalContainer>
      </Container>
    </>
  );
};

export default AdmissionData;
