import initialState from "../initialStateFile";

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loading: true,
        error: null, // Reset error state when a new login request is made
        email: action.payload.email, // Save email from payload
        password: action.payload.password, // Save password from payload
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authToken: action.payload.authToken,
        user: action.payload.user,
        loading: false,
        error: null,
        email: null, // Reset email after successful login
        password: null, // Reset password after successful login
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload, // Store error message received from the server
        email: null, // Reset email after login failure
        password: null, // Reset password after login failure
      };
    case 'LOGOUT':
      return {
        ...state,
        authToken: null,
        user: null,
      };
    default:
      return state;
  }
};

export default authReducer;
