import React from 'react'
import Navbar from '../component/navbar/Navbar'
import AdmissionData from "../component/admission/AdmissionData"
const AdmissionMain = () => {
  return (
   <>
   <Navbar>
    <AdmissionData></AdmissionData>
   </Navbar>
   </>
  )
}

export default AdmissionMain