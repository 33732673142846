import React from "react";
import Navbar from "../component/navbar/Navbar";
import HomePage from "../component/homepage/homePage";
const Home = () => {
  return (
    <>
      <Navbar>
        <HomePage></HomePage>
      </Navbar>
    </>
  );
};

export default Home;
