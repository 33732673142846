import React, { useContext } from "react";
import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
import Dasboard from "./component/dasboard/Dasboard";
import Admission from "./pages/Admission";
import AdmissionMain from "./pages/AdmissionMain";
import LoginPage from "./component/loginpage/LoginPage";
import InquriCreate from "./pages/InquriCreate";
import SingleStudent from "./component/studentpage/SingleStudent";
import InquriDataPage from "./pages/InquriDataPage";
import FaclityProfileCard from "./pages/FaclityProfileCard";
import Recepit from "./pages/Recepit";
import CreateFaclity from "./pages/CreateFaclity";
import CreateRecepit from "./pages/CreateRecepit";
import CreateBatches from "./pages/CreateBatches";
import GetBatches from "./pages/GetBatches";
import InvoiceDegin from "./component/recepitpage/InvoiceDegin";
import ExamHall from "./pages/ExamHall";
import Lms from "./pages/Lms";
import BranchMaterial from "./pages/BranchMaterial";
import LoadingSpinner from "./component/lodingpage/LoadingPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MonthlyCollection from "./pages/MonthlyCollection";
import LabFind from "./pages/LabFind";
import CreatedLab from "./pages/CreatedLab";
import Example from "./component/utilites/Example";
import LabDetails from "./pages/LabDetails";
import FindAllLabPc from "./pages/FindAllLabPc";
import AdminDasbordPage from "./adminPages/AdminDasbordPage";
import ErrorPage from "./component/utilites/404Error";
import UserRaister from "./component/adminDasboard/UserRagister";
import AdminInquiryData from "./component/adminDasboard/GetInquri";
import AdminInsideCard from "./component/admincards/InsideCards";
import AdminAdmissionData from "./component/adminDasboard/GetAdmission";
import AdminInquri from "./adminPages/AdminInquri";
import AdminAdmission from "./adminPages/AdminAdmission";
import CreateAdmissionbyInquriy from "./component/admission/CreateAdmissionbyInquriy";
import CaddmissionFinquiry from "./pages/CaddmissionFinquiry";
import CreateOthRecptive from "./component/other-receptiv/CreateRecptiv";
import OtherRecptiv from "./pages/OtherRecptiv";
import GetOtherInvoice from "./pages/GetOtherInvoice";
import OtherInvoiceDegin from "./component/other-receptiv/OtherRecptive";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/home",
    element: <Home></Home>,
  },
  {
    path: "/dashboard",
    element: <Dasboard></Dasboard>,
  },
  {
    path: "/create-admission",
    element: <Admission></Admission>,
  },
  {
    path: "/create-admission/:id",
    element: <Admission></Admission>,
  },
  {
    path: "/create-admission-inquiry/:id",
    element: <CaddmissionFinquiry></CaddmissionFinquiry>,
  },

  {
    path: "/admissiondata",
    element: <AdmissionMain></AdmissionMain>
  },
  {
    path: "/single-student/:id",
    element: <SingleStudent></SingleStudent>
  },
  {
    path: "/create-inquri",
    element: <InquriCreate></InquriCreate>,
  },
  {
    path: "/create-inquri/:id",
    element: <InquriCreate></InquriCreate>,
  },
  {
    path: "/inquri-data",
    element: <InquriDataPage></InquriDataPage>,
  },
  {
    path: "/recepit-data",
    element: <Recepit></Recepit>,
  },
  {
    path: "/faclity-profile",
    element: <FaclityProfileCard></FaclityProfileCard>,
  },
  {
    path: "/create-faclity-profile",
    element: <CreateFaclity></CreateFaclity>,
  },
  {
    path: "/create-fees-recepit",
    element: <CreateRecepit></CreateRecepit>,
  },
  {
    path: "/create-fees-recepit/:id",
    element: <CreateRecepit></CreateRecepit>,
  },
  {
    path: "/find-batchs",
    element: <GetBatches></GetBatches>,
  },
  {
    path: "/create-batchs",
    element: <CreateBatches></CreateBatches>,
  },
  {
    path: "/invoice-details/:id",
    element: <InvoiceDegin></InvoiceDegin>,
  },
  {
    path: "/eaxmtickt-hall",
    element: <ExamHall> </ExamHall>
  },
  {
    path: "/create-lms",
    element: <Lms></Lms>
  },
  {
    path: "/create-branch-material",
    element: <BranchMaterial></BranchMaterial>
  },
  {
    path: "/loading",
    element: <LoadingSpinner></LoadingSpinner>
  },
  {
    path: "/monthly-pay",
    element: <MonthlyCollection></MonthlyCollection>
  },
  {
    path: "/create-lab",
    element: <CreatedLab></CreatedLab>
  },
  {
    path: "/find-lab",
    element: <LabFind></LabFind>
  },
  {
    path: "/example",
    element: <Example></Example>
  },
  {
    path: "/lab-pc-from/:id",
    element: <LabDetails></LabDetails>
  },
  {
    path: "/lab-pc-from/:id/:pcId",
    element: <LabDetails></LabDetails>
  },
  {
    path: "/get-lab-allpc/:id",
    element: <FindAllLabPc></FindAllLabPc>
  },
  {
    path: "/create-other-receptiv",
    element: <OtherRecptiv></OtherRecptiv>
  },
  {
    path: "/get-other-receptiv",
    element: <GetOtherInvoice></GetOtherInvoice>
  },
  {
    path: "/get-other-receptiv-design/:id",
    element: <OtherInvoiceDegin></OtherInvoiceDegin>
  },



  // admin routes is start from there 

  {
    path: "/admin-dashboard",
    element: <AdminDasbordPage></AdminDasbordPage>
  },
  {
    path: "/admin-insidecard/inquiry/:id",
    element: <AdminInquri></AdminInquri>
  },
  {
    path: "/admin-insidecard/admission/:id",
    element: <AdminAdmission></AdminAdmission>
  },
  // {
  //   path: "/admin-insidecard/collection/:id",
  //   element: <AdminInquiryData></AdminInquiryData>
  // },
  {
    path: "/user-ragister",
    element: <UserRaister> </UserRaister>
  },
  {
    path: "/user-ragister",
    element: <UserRaister> </UserRaister>
  },
  {
    path: "/admin-insidecard/:id",
    element: <AdminInsideCard></AdminInsideCard>
  },
  {
    path: "*",
    element: <ErrorPage></ErrorPage>
  },  
 
]);
function App() {
  return (
    <>
      <div className="App">
      <ToastContainer />
       
        <RouterProvider router={router} />
      </div>
    </>
  );
}

export default App;
