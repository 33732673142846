import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const BatchForm = () => {
  const navigate = useNavigate();
  const [selectedTimeRange, setSelectedTimeRange] = useState("");
  const [facilityName, setFacilityName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [batchTime, setBatchTime] = useState("");
  const [batchTitle, setBatchTitle] = useState("");
  const [labName, setLabName] = useState("");
  const [remark, setRemark] = useState("");
  const authToken = localStorage.getItem("auth-token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [students, setStudents] = useState([]);
  const [studentName, setStudentName] = useState([]);
  const timeRanges = [
    "9:00AM - 11:00AM",
    "11:00AM - 1:00PM",
    "1:00PM - 3:00PM",
    "3:00PM - 5:00PM",
    "5:00PM - 7:00PM",
  ];

  useEffect(() => {
    // Fetch student names from API
    axios
      .get(`${apiUrl}/student/students/names-ids`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setStudents(response.data.students);
      })
      .catch((error) => {
        console.error("Error fetching students:", error);
      });
  }, [apiUrl, authToken]);
  useEffect(() => {
    console.log(students);
  }, [students]);
  const handleStudentSelect = (event) => {
    setStudentName(event.target.value); // Store the array of selected student IDs
    console.log("Selected Students:", event.target.value); // Log selected student names to the console
  };
  const handleBatchTimeChange = (e) => {
    setBatchTime(e.target.value);
    setSelectedTimeRange(e.target.value);
  };

  const handleSubmit = () => {
    // Prepare batch data
    const batchData = {
      facilityName,
      startDate,
      endDate,
      batchTime,
      batchTitle,
      labName,
      remark,
      studentName,
    };

    // Send batch data to the API

    // Send batch data to the API with the auth token included in the headers
    axios
      .post(`${apiUrl}/batch/batches`, batchData, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      })

      .then((response) => {
        console.log("Batch created successfully:", response.data);
        navigate("/find-batchs");
        // Optionally, you can navigate to another page or perform any other action after successful submission
      })

      .catch((error) => {
        console.error("Error creating batch:", error);
      });
  };

  return (
    <div
      className="maincontainer-facliy"
      style={{
        padding: "22px",
        backgroundColor: "white",
        margin: "13px",
        borderRadius: "7px",
      }}
    >
      <h2 style={{ textAlign: "center", margin: "3px" }}>Create Batch</h2>
      <Grid container spacing={2} style={{ marginTop: "30px" }}>
        <Grid item xs={6}>
          <TextField
            label="Facility Name"
            fullWidth
            value={facilityName}
            onChange={(e) => setFacilityName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Topic"
            fullWidth
            type="text"
            value={batchTitle}
            onChange={(e) => setBatchTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Start Date"
            fullWidth
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }} // This will shrink the label to make space for the input
            inputProps={{ style: { fontSize: 16 } }} // This will set the font size of the input
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="End Date"
            fullWidth
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }} // This will shrink the label to make space for the input
            inputProps={{ style: { fontSize: 16 } }} // This will set the font size of the input
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="batch-time-label">Batch Time</InputLabel>
            <Select
              labelId="batch-time-label"
              id="batch-time-select"
              value={selectedTimeRange}
              onChange={handleBatchTimeChange}
            >
              {timeRanges.map((range, index) => (
                <MenuItem key={index} value={range}>
                  {range}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="student-select-label">Select Students</InputLabel>
            <Select
              labelId="student-select-label"
              id="student-select"
              multiple
              value={studentName}
              onChange={handleStudentSelect}
              renderValue={(selected) => selected.join(", ")}
            >
              {students.map((student) => (
                <MenuItem key={student.id} value={student.studentName}>
                  {student.studentName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Lab Name"
            fullWidth
            value={labName}
            onChange={(e) => setLabName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Remark"
            fullWidth
            type="text"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default BatchForm;
