import React from "react";
import Navbar from "../component/navbar/Navbar";
import InquriData from "../component/inquripage/InquriData";
const InquriDataPage = () => {
  return (
    <>
      <Navbar>
        <InquriData></InquriData>
      </Navbar>
    </>
  );
};

export default InquriDataPage;
