import React from 'react'
import Navbar from '../component/navbar/Navbar'
import FindLab from '../component/Labpage/FindLab'

const LabFind = () => {
  return (
   <>
   <Navbar>
    <FindLab></FindLab>
   </Navbar>
   </>
  )
}

export default LabFind