import React, { useState, useEffect } from "react";
import styled from "styled-components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { DatePicker, Select, Button } from "antd";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import moment from "moment";
import LoadingSpinner from "../lodingpage/LoadingPage";
import { useNavigate } from "react-router-dom";
import {useParams} from "react-router-dom"
const { Option } = Select;
const { RangePicker } = DatePicker;

const Container = styled.div`
  height: 288px;
  overflow: scroll;
  position: relative;
`;

const Table = styled.table`
  border: 0.01px solid rgba(0, 0, 0, 0.272);
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const TableCell = styled.td`
  border-bottom: 0.01px solid rgba(67, 66, 66, 0.636);
  height: 30px;
  padding: 8px 20px;
  font-size: 13px;
  margin-left: 20px; // Unnecessary margin
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconCell = styled.td`
  text-align: center;
`;

const AdminAdmissionData = () => {
  const {id}= useParams()
  const authToken = localStorage.getItem("auth-token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([]);
  const [error, setError] = useState(null);
  const [totalAdmission, setTotalAdmission] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      if (!authToken) {
        console.error("No auth token found.");
        return;
      }

      const response = await axios.get(`${apiUrl}/admin-dashboard/get-useradmission/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      setTotalAdmission(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data");
    }
  };

  return (
    <>
      <div className="col-sm-12 mb-3 mb-sm-0" style={{ margin: "auto", width: "96%", marginTop: "20px" }}>
        <div className="card w-100">
          <div className="card-body" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h4 className="card-title" style={{ fontSize: "25px" }}>Admissions List</h4>
            <h6 className="admissionf1">
              <Link to="/admin-dashboard">Home</Link>
              <ArrowBackIosIcon style={{ fontSize: "15px", marginLeft: "13px" }} /> Admission
            </h6>
          </div>
        </div>
      </div>
      
      <Link to="">
        <Button style={{ margin: "5px", backgroundColor: "#5151d7", width: "200px", color: "white" }}>Admissions</Button>
      </Link>
      <Container>
        <Table>
          <tbody>
            <TableRow className="first">
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile No.</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Total Fees</TableCell>
              <TableCell>Inst. Amount</TableCell>
              <TableCell>Counselor</TableCell>
              <TableCell>Course Type</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Duration</TableCell>
              <IconCell />
            </TableRow>
            {totalAdmission &&
              totalAdmission?.map((admission, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <RemoveRedEyeIcon style={{ cursor: "pointer" }} />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{admission.name}</TableCell>
                  <TableCell>{admission.email}</TableCell>
                  <TableCell>{admission.mobileNumber}</TableCell>
                  <TableCell>{admission.cource}</TableCell>
                  <TableCell>{moment(admission.createdAt).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{admission.totalFees}</TableCell>
                  <TableCell>{admission.installmentAmount}</TableCell>
                  <TableCell>{admission.councellingBy}</TableCell>
                  <TableCell>{admission.courceType}</TableCell>
                  <TableCell>{admission.city}</TableCell>
                  <TableCell>{admission.durationCource}</TableCell>
                </TableRow>
              ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default AdminAdmissionData;
