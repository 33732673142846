import React from "react";
import Navbar from "../component/navbar/Navbar";
import CreateBranchMaterial from "../component/branchmaterial/CreateBranchMaterial";

const BranchMaterial = () => {
  return (
    <>
      <Navbar>
        <CreateBranchMaterial></CreateBranchMaterial>
      </Navbar>
    </>
  );
};

export default BranchMaterial;
