import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import axios from "axios";
import {
  Button,
  message,
  Steps,
  theme,
  Input,
  Row,
  Select,
  DatePicker,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
const { Option } = Select;

const { Step } = Steps;

const CreateInquri = () => {
  const [date, setDate] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const authToken = localStorage.getItem("auth-token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    category: "",
    fatherName: "",
    fatherMobilenumber: "",
    fatherOccuption: "", // Fixed typo in occupation
    address: "",
    dathofBirth: "", // Fixed typo in variable name
    city: "",
    class10th: "",
    class12th: "",
    diploma: "",
    specialization: "",
    courceType: "",
    class10thbord: "", // Changed to class10thboard for consistency
    class10thparcent: "", // Changed to class10thpercent for consistency
    class10thpassingyear: "",
    class12thbord: "", // Changed to class12thboard for consistency
    class12thparcent: "", // Changed to class12thpercent for consistency
    class12thpassingyear: "",
    diplomauniversity: "",
    diplomapercent: "",
    diplomapassingyear: "",
    source: "",
    counclingby: "", // Changed to counsellingBy for consistency
    cource: "", // Changed to course for consistency
    amount: "",
    remark: "",
    telicaller: "", // Changed to telecaller for consistency
    status: "",
    closeReason: "",
  });

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (id) {
      fetchInquiryData(); // Fixed typo in function name
      setIsUpdating(true);
    }
  }, [id]);

  const fetchInquiryData = async () => {
    // Fixed typo in function name
    try {
      const response = await axios.get(`${apiUrl}/inquiry/get-inquri/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      // console.log(response.data);
      setFormData(response.data.inquri);
    } catch (error) {
      message.error("API Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isUpdating) {
        await updateInquiry(); // Fixed typo in function name
      } else {
        await createInquiry(); // Fixed typo in function name
      }
    } catch (error) {
      console.error("API Error:", error);
      // Display error message using toast
      message.error(error.response?.data?.message || "An error occurred");
    }
  };

  const createInquiry = async () => {
    // Fixed typo in function name
    try {
      const response = await axios.post(
        `${apiUrl}/inquiry/addinquri`, // Fixed typo in URL
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      handleApiResponse(response);
    } catch (error) {
      console.error("API Error:", error);
      // Display error message using toast
      message.error(error.response?.data?.message || "An error occurred");
    }
  };

  const updateInquiry = async () => {
    // Fixed typo in function name
    try {
      const response = await axios.put(
        `${apiUrl}/inquiry/update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );

      handleApiResponse(response);
    } catch (error) {
      console.error("API Error:", error);
      // Display error message using toast
      message.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleApiResponse = (response) => {
    if (response.data?.success) {
      message.success(
        isUpdating ? "Updated Successfully" : "Inquri Created Successfully"
      );
      navigate("/inquri-data");
    } else {
      message.error(response.data?.message);
    }
  };
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const [selectedOption, setSelectedOption] = useState("");

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const steps = [
    {
      title: "First",
      content: (
        <div>
          <h3 style={{ marginTop: "10px" }}>
            Step 1: Enter Student Personal Details{" "}
          </h3>
          <Row justify="space-around" display="flex">
            <Input
              className={
                formData.name === "" && current === 0 ? "required-field" : ""
              }
              placeholder=" Student Name"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <Input
              className={
                formData.mobileNumber === "" && current === 0
                  ? "required-field"
                  : ""
              }
              placeholder="Mobile Number"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="number" // Set type to "number"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
              pattern="[0-9]*" // Use pattern attribute to accept only numeric characters
            />

            <Input
              placeholder="Email ID"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
            >
              <option value="">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>

            <Input
              placeholder="Father Name"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="fatherName"
              value={formData.fatherName}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Parent's Mobile Number"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="number"
              name="fatherMobilenumber"
              value={formData.fatherMobilenumber}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Father Occupation"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="fatherOccuption"
              value={formData.fatherOccuption}
              onChange={handleChange}
              required
            />

            <input
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              placeholder="Enter DOB"
              className="form-control"
              name="dathofBirth"
              type={isFocused ? "date" : "text"}
              value={formData.dathofBirth}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              id="date"
              required
            />

            <Input
              placeholder="Residence Address"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />

            <input
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              placeholder="Inquiry Date"
              className="form-control"
              name="inquriDate"
              type={isFocused ? "date" : "text"}
              value={formData.inquriDate}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              id="date"
              required
            />

            <Input
              placeholder="Remarke "
              style={{
                margin: "10px",
                width: "60%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="remark"
              value={formData.remark}
              onChange={handleChange}
              required
            />
          </Row>
        </div>
      ),
    },
    {
      title: "Second",
      content: (
        <div>
          <h3 style={{ marginTop: "10px" }}>
            Step 2: Enter Student Qulification & Course Details
          </h3>
          <Row justify="space-around" display="flex">
            {/* <Input
              placeholder="Class 12th Board"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="class12thbord"
              value={formData.class12thbord}
              onChange={handleChange}
              required
            /> */}
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="class12thbord"
              value={formData.class12thbord}
              onChange={handleChange}
              required
            >
              <option value=""> High Education</option>
              <option value="10th">10th</option>
              <option value="12th">12th</option>
              <option value="Diploma">Diploma</option>
              <option value="ITI">ITI</option>
              <option value="Other"> Other</option>
            </select>
            <Input
              placeholder=" Subject"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="class12th"
              value={formData.class12th}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Percentage"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="class12thparcent"
              type="number"
              value={formData.class12thparcent}
              onChange={handleChange}
              required
            />
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="class12thpassingyear"
              value={formData.class12thpassingyear}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Select a year
              </option>
              {Array.from({ length: 30 }, (_, index) => {
                const year = 1999 + index;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>

            <Input
              placeholder="Graducation/Post Graducation"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="diploma"
              value={formData.diploma}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Specialization"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="specialization"
              value={formData.specialization}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="University"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="diplomauniversity"
              value={formData.diplomauniversity}
              onChange={handleChange}
              required
            />
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="diplomapassingyear"
              value={formData.diplomapassingyear}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Select a year
              </option>
              {Array.from({ length: 30 }, (_, index) => {
                const year = 1999 + index;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>

            <Input
              placeholder="Percentage"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="diplomapercent"
              type="number"
              value={formData.diplomapercent}
              onChange={handleChange}
              required
            />
            {/* <Input
              placeholder="Course Inquri"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="cource"
              value={formData.cource}
              onChange={handleChange}
              required
            /> */}

            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="cource"
              value={formData.cource}
              onChange={handleChange}
              required
            >
              <option value="Course Inquri">Course Inquri</option>
              <option value="Full Stack Java">Full Stack Java</option>
              <option value="Full Stack Python">Full Stack Python</option>
              <option value="PGDDA">PGDDA</option>
              <option value="PGDFE">PGDFE</option>
              <option value="PGDIE">PGDIE</option>
              <option value="MERN/MEAN Stack">MERN/MEAN Stack</option>
              <option value="ITI">ADWD</option>
              <option value="Other"> Other</option>
            </select>

            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="courceType"
              value={formData.courceType}
              onChange={handleChange}
              required
            >
              <option value="">Inquired For</option>
              <option value="Career Cource">Career Cource</option>
              <option value="Module Cource">Module Cource</option>
              <option value="Internship">Internship</option>
              <option value="Magar Training">Magar Training</option>
              <option value=" Field Projects"> Field Projects</option>
              <option value="Other"> Other</option>
            </select>

            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="source"
              value={formData.source}
              onChange={handleChange}
              required
            >
              <option value="">Select a source</option>
              <option value="Social Media">Social Media</option>
              <option value="Directly">Directly</option>
              <option value="Telecaller">Telecaller</option>
              <option value="Pamplate">Pamplate</option>
              <option value="News Pepar">News Pepar</option>
              <option value="Hoarding">Hoarding</option>
              <option value="Student Reference">Student Reference</option>
              <option value="Other">Other</option>
            </select>

            <Input
              placeholder="Tele Counselor Name"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              disabled={formData.source !== "Telecaller"}
              name="telicaller"
              value={formData.telicaller}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Counselor Name"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="counclingby"
              value={formData.counclingby}
              onChange={handleChange}
              required
            />
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "5px",
                borderRadius: "5px",
              }}
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
              required
            >
              <option value="">Select a Status</option>
              <option value="Running">Running</option>
              <option value="Admitted">Admitted</option>
              <option value="Closed">Closed</option>
              {/* Add more options as needed */}
            </select>
          </Row>
        </div>
      ),
    },
  ];
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: "black",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px dashed gray",
    marginTop: "16px",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "20px",
        padding: "20px",
      }}
    >
      <div style={{ marginBottom: "24px" }}>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>
      <div style={contentStyle}>{steps[current].content}</div>
      <div style={{ marginTop: "24px" }}>
        {current < steps.length - 1 && (
          <Button
            type="primary"
            style={{ backgroundColor: "#1677ff" }}
            onClick={next}
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            style={{ backgroundColor: "#1677ff" }}
            onClick={handleSubmit}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={prev}>
            Previous
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreateInquri;
