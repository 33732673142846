import React from 'react'
import Navbar from '../component/navbar/Navbar'
import PCForm from '../component/utilites/AddlabDetils'

const LabDetails = () => {
  return (
  <>
  <Navbar>
    <PCForm></PCForm>
  </Navbar>
  </>
  )
}

export default LabDetails