import React, { useState, useEffect } from "react";
import { Button, Steps, Input, Row, Col, Select, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Step } = Steps;
const { Option } = Select;
const CreateAdmission = () => {
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const authToken = localStorage.getItem("auth-token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const { id } = useParams();
  const [formData, setFormData] = useState({
    studentid: "",
    email: "",
    fromnumber: "",
    cource: "",
    totalFees: "",
    discount: "",
    otherFees: "",
    lmsFees: "",
    name: "",
    dateofBrearth: "",
    gender: "",
    category: "",
    residenceAddress: "",
    permanentAddress: "",
    city: "",
    mobileNumber: "",
    parentsContact: "",
    degree: "",
    passingYear: "",
    university: "",
    courceType: "",
    durationCource: "",
    fatherName: "",
    specialization: "",
    remark: "",
    ragistationFees: "",
    feesBy: "",
    councellingBy: "",
    downPayment: "",
    installment: "",
    admissionDate: " ",
    downPaymentdate: "",
  });

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (id) {
      fatchAdmissionData(); // Fixed typo in function name
      setIsUpdating(true);
    }
  }, [id]);

  const fatchAdmissionData = async () => {
    // Fixed typo in function name
    try {
      const response = await axios.get(`${apiUrl}/student/get-student/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      console.log(response.data);
      setFormData(response.data.admission);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isUpdating) {
        await updateAdmission(); // Fixed typo in function name
      } else {
        await createAdmission(); // Fixed typo in function name
      }
    } catch (error) {
      console.error("API Error:", error);
      // Display error message using toast
      message.error(error.response?.data?.message || "An error occurred");
    }
  };

  const createAdmission = async () => {
    // Fixed typo in function name
    try {
      const response = await axios.post(
        `${apiUrl}/student/add-student`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      handleApiResponse(response);
    } catch (error) {
      console.error("API Error:", error);
      // Display error message using toast
      message.error(error.response?.data?.message || "An error occurred");
    }
  };

  const updateAdmission = async () => {
    // Fixed typo in function name
    try {
      const response = await axios.put(
        `${apiUrl}/student/update-student/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );

      handleApiResponse(response);
    } catch (error) {
      console.error("API Error:", error);
      // Display error message using toast
      message.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleApiResponse = (response) => {
    if (response.data?.success) {
      message.success(
        isUpdating ? "Updated Successfully" : "Admission Created Successfully"
      );
      navigate("/admissiondata");
    } else {
      message.error(response.data?.message);
    }
  };

  const calculateTotalFees = () => {
    const { totalFees, discount, otherFees } = formData;
    const calculatedTotal =
      parseFloat(totalFees || 0) -
      parseFloat(discount || 0) +
      parseFloat(otherFees || 0);

    return calculatedTotal.toFixed(2);
  };

  const calculateRemainingFees = () => {
    const totalFees = parseFloat(calculateTotalFees());
    const totalDownPayment = parseFloat(formData.downPayment || 0);
    const totalRegistrationFees = parseFloat(formData.ragistationFees || 0);
    const remainingFees = totalFees - totalDownPayment - totalRegistrationFees;
    return remainingFees.toFixed(2);
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "First",
      content: (
        <div>
          <h3 style={{ marginTop: "10px" }}>
            Step 1: Enter Student Personal Details{" "}
          </h3>
          <Row justify="space-around" display="flex">
            <Input
              placeholder="Student ID"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="studentid"
              value={formData.studentid}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Roll Number"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="fromnumber"
              value={formData.fromnumber}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Student Name"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="mobile number"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
            />
            <Input
              placeholder="Email ID"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <Input
              placeholder="Father Name"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="fatherName"
              value={formData.fatherName}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Father Mobile Number"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="parentsContact"
              value={formData.parentsContact}
              onChange={handleChange}
              required
            />

            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
            >
              <option value="">Select Category</option>
              <option value="General">General</option>
              <option value="OBC">OBC</option>
              <option value="ST/SC">ST/SC</option>
            </select>
            <Input
              placeholder="Residence Address"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="residenceAddress"
              value={formData.residenceAddress}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Permanent Address"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="permanentAddress"
              value={formData.permanentAddress}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="City"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </Row>
        </div>
      ),
    },
    {
      title: "Second",
      content: (
        <div>
          <h3 style={{ marginTop: "10px" }}>
            Step 2: Enter Student Course Admission Details
          </h3>
          <Row justify="space-around" display="flex">
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="degree"
              value={formData.degree}
              onChange={handleChange}
              required
            >
              <option value="">Qulification</option>
              <option value="Under Graduate">Under Graduate</option>
              <option value="Graduate">Graduate</option>
              <option value="Post Graduate">Post Graduate</option>
              <option value="Other">Other</option>
            </select>
            <Input
              placeholder="Speslization"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="specialization"
              value={formData.specialization}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Univercity"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="university"
              value={formData.university}
              onChange={handleChange}
              required
            />
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="passingYear"
              value={formData.passingYear}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Select a year
              </option>
              {Array.from({ length: 30 }, (_, index) => {
                const year = 1999 + index;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
            {/* <Input
              placeholder="Cource Name"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="cource"
              value={formData.cource}
              onChange={handleChange}
              required
            /> */}

            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="cource"
              value={formData.cource}
              onChange={handleChange}
              required
            >
              <option value="">Course Name</option>
              <option value="Full Stack Java">Full Stack Java</option>
              <option value="Full Stack Pytho">Full Stack Python</option>
              <option value="Full Stack MERN/MEAN">Full Stack MERN/MEAN</option>
              <option value="PGDIE">PGDIE</option>
              <option value="PGDFE">PGDFE</option>
              <option value="PGDDE">PGDDE</option>
              <option value="Other"> Other</option>
            </select>
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="courceType"
              value={formData.courceType}
              onChange={handleChange}
              required
            >
              <option value="">Cource type</option>
              <option value="Career Course">Career Course</option>
              <option value="Module Course">Module Course</option>
              <option value="Internship">Internship</option>
              <option value="Magar Training">Magar Training</option>
              <option value=" Field Projects"> Field Projects</option>
              <option value="Other"> Other</option>
            </select>

            {/* <Input
              placeholder="Duraction"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="durationCource"
              value={formData.durationCource}
              onChange={handleChange}
              required
            /> */}

            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="durationCource"
              value={formData.durationCource}
              onChange={handleChange}
              required
            >
              <option value="">Duraction</option>
              <option value="3 Months">3 Months</option>
              <option value="6 Months">6 Months</option>
              <option value="9 Months">9 Months</option>
              <option value="1 Years"> 1 Years</option>
              <option value="Other"> Other</option>
            </select>

            <input
              placeholder="Enter DOB"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="date"
              name="dateofBrearth"
              defaultValue={formData.dateofBrearth}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Counsulling By"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="councellingBy"
              value={formData.councellingBy}
              onChange={handleChange}
              required
            />
          </Row>
        </div>
      ),
    },
    {
      title: "Last",
      content: (
        <div>
          <h3 style={{ marginTop: "10px" }}>
            Step 3: Enter Student Fees Details
          </h3>
          <Row justify="space-around" display="flex">
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="feesBy"
              value={formData.feesBy}
              onChange={handleChange}
              required
            >
              <option value="">Payment Model</option>
              <option value="PAT">PAT</option>
              <option value="PAP">PAP</option>
              <option value="Modular">Modular</option>
              <option value="Other">Other</option>
            </select>
            <Input
              placeholder="Fees"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="totalFees"
              value={formData.totalFees}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Pay After Placement"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="lmsFees"
              disabled={formData.feesBy !== "PAP"}
              value={formData.lmsFees}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Other Fees"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="otherFees"
              value={formData.otherFees}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Discount"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="discount"
              value={formData.discount}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Ragistation Fees"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="ragistationFees"
              value={formData.ragistationFees}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Down payment"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="downPayment"
              value={formData.downPayment}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Reaming Fees"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              value={calculateRemainingFees()}
              readOnly // Make it read-only
            />
            {/* <Input
              placeholder="Installment"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="installment"
              value={formData.installment}
              onChange={handleChange}
              required
            /> */}

            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="installment"
              value={formData.installment}
              onChange={handleChange}
              required
            >
              <option value="">Installment</option>
              <option value="1">1 </option>
              <option value="2">2 </option>
              <option value="3">3 </option>
              <option value="4">4 </option>
              <option value="5">5 </option>
              <option value="6">6 </option>
              <option value="7">7 </option>
              <option value="8">8 </option>
              <option value="9">9 </option>
              <option value="10">10 </option>
            </select>

            <input
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              placeholder="Admission Date"
              className="form-control"
              name="admissionDate"
              type={isFocused ? "date" : "text"}
              value={formData.admissionDate}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              id="date"
              required
            />
            <Input
              placeholder="Remark"
              style={{
                margin: "10px",
                width: "60%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="remark"
              value={formData.remark}
              onChange={handleChange}
              required
            />
          </Row>
        </div>
      ),
    },
  ];
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    // color: token.colorTextTertiary,
    // backgroundColor: token.colorFillAlter,
    // borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    // with:"58rem"
  };

  return (
    <>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: "20px",
          padding: "20px",
        }}
      >
        <div style={{ marginBottom: "24px" }}>
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div style={contentStyle}>{steps[current].content}</div>
        <div style={{ marginTop: "24px" }}>
          {current < steps.length - 1 && (
            <Button
              type="primary"
              style={{ backgroundColor: "#1677ff" }}
              onClick={next}
            >
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              style={{ backgroundColor: "#1677ff" }}
              onClick={handleSubmit}
            >
              Done
            </Button>
          )}
          {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
                backgroundColor: "#1677ff",
                color: "white",
              }}
              onClick={prev}
            >
              Previous
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateAdmission;
