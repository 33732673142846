import React from "react";
import Navbar from "../component/navbar/Navbar";
import MonthlyDownPaymentIncome from "../component/utilites/Getmonthly";

const MonthlyCollection = () => {
  return (
    <>
      <Navbar>
        <MonthlyDownPaymentIncome />
      </Navbar>
    </>
  );
};

export default MonthlyCollection;
