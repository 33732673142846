import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { message } from "antd";             
import {
  TextField,
  Button,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";

const RecepitPage = () => {
  const authToken = localStorage.getItem("auth-token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState(null);
  const [studentName, setStudentName] = useState("");
  const [studentId, setStudentId] = useState("");
  const [date , setDate] = useState("");
  const [cource, setCource] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [currentPaidAmount, setCurrentPaidAmount] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/student/get-student/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );
        if (response.data.success) {
          setStudentData(response.data.admission);
          if (response.data.admission) {
            setStudentId(response.data.admission.studentid);
            setStudentName(response.data.admission.name);
            setCurrentPaidAmount(response.data.admission.installmentAmount);
            setCource(response.data.admission.cource);
          }
        } else {
          message.error(response.data.message);
        }
      } catch (error) {
        message.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Get current date
    // const date = new Date();
    // const formattedDate = `${date.getDate()}/${
    //   date.getMonth() + 1
    // }/${date.getFullYear()}`;
    // setCurrentDate(formattedDate);
  }, [id, authToken]);

  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleCreateInvoice = async () => {
    try {
      if (!authToken) {
        throw new Error("Authentication token is missing");
      }

      const response = await axios.post(
        `${apiUrl}/invoice/create-invoice`,
        {
          studentId,
          studentName,
          paymentMethod,
          currentPaidAmount,
          date,
          cource,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 201 && response.statusText === "Created") {
        message.success(response.data.message || "Invoice created successfully");
        navigate("/recepit-data");
      }
    } catch (error) {
      console.error("Error creating invoice:", error);
      message.error(error.response?.data?.message || "An error occurred");

      if (error.response && error.response.status === 401) {
        message.error("Unauthorized - Check your authentication token");
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        className="col-sm-12 mb-3 mb-sm-0"
        style={{ margin: "auto", width: "96%", marginTop: "30px" }}
      >
        <div className="card w-100">
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="card-title">Create Receipt</h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon
                style={{ fontSize: "15px", marginLeft: "13px" }}
              />{" "}
              Receipt
            </h6>
          </div>
        </div>
      </div>
      <div
        className="maincontainer-facliy"
        style={{
          padding: "22px",
          backgroundColor: "white",
          margin: "20px",
          borderRadius: "7px",
        }}
      >
        <form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Student ID"
                value={studentData && studentData.studentid}
                onChange={(e) => setStudentId(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Student Name"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="payment-method-label">
                  Payment Method
                </InputLabel>
                <Select
                  labelId="payment-method-label"
                  name="paymentMethod"
                  value={paymentMethod}
                  onChange={handleChange}
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Card">Card</MenuItem>
                  <MenuItem value="Online">Online</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Amount"
                type="number"
                value={currentPaidAmount}
                onChange={(e) => setCurrentPaidAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="date"
                label="Date"
                value={date}
                onChange={(e)=> setDate(e.target.value)}
                // InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Course"
                value={studentData && studentData.cource}
                onChange={(e) => setCource(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateInvoice}
            style={{ marginTop: "1em" }}
          >
            Create Invoice
          </Button>
        </form>
      </div>
    </>
  );
};

export default RecepitPage;
