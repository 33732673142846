import React from "react";
import NavbarAdmin from "../component/adminDasboard/NavbarAdmin";
import AdminInquiryData from "../component/adminDasboard/GetInquri";

const AdminInquri = () => {
  return (
    <>
      <NavbarAdmin>
        <AdminInquiryData></AdminInquiryData>
      </NavbarAdmin>
    </>
  );
};

export default AdminInquri;
