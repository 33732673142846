import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  DashboardOutlined,
  ScheduleOutlined,
  UserAddOutlined,
  ProductOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  SubnodeOutlined,
  CalculatorOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const { Header, Sider, Content } = Layout;

const NavbarAdmin = ({ children }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const fetchUserData = async () => {
      const authToken = localStorage.getItem("auth-token");

      if (authToken) {
        try {
          const response = await axios.post(
            `${apiUrl}/auth/getuser`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                "auth-token": authToken,
              },
            }
          );
          // Handle successful response
          setUserName(response.data.name);
          console.log(response.data.name);
        } catch (error) {
          // Handle error
          if (error.response && error.response.status === 401) {
            console.error(
              "Unauthorized: Please authenticate using a valid token."
            );
            // Handle authentication error (e.g., redirect to login page)
          } else {
            console.error("Error fetching user data:", error);
          }
        } finally {
          // Set loading state to false
          setLoading(false);
        }
      }
    };

    fetchUserData(); // Call the fetchUserData function
  }, []); // Empty dependency array means this useEffect will only run once, similar to componentDidMount

  const handleLogout = () => {
    // Clear authentication token from local storage
    localStorage.removeItem("auth-token");
    // Redirect to home page
    navigate("/");
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const items = [
    {
      key: "1",
      icon: <DashboardOutlined />,
      label: "Admin Dasboard",
      link: "/admin-dashboard",
    },
    {
      key: "2",
      icon: <UserAddOutlined />,
      label: "Create User",
      link: "/user-ragister",
    },
    {
      key: "3",
      icon: <KeyOutlined />,
      label: "Change User Password",
      link: "/user-ragister",
    },
    {
      key: "4",
      icon: <SubnodeOutlined />,
      label: "Add Notification",
      link: "/home",
    },

    {
      key: "5",
      icon: <CalculatorOutlined />,
      label: "Month By Report",
      link: "/admin-monthly-pay",
    },
    {
      key: "6",
      icon: <UserAddOutlined />,
      label: "Faclity",
      link: "/admin-faclity-profile",
    },
    {
      key: "7",
      icon: <ScheduleOutlined />,
      label: "Faculity Batches",
      link: "/admin-find-batchs",
    },
    {
      key: "8",
      icon: <ProductOutlined />,
      label: "Branch Material",
      link: "/create-branch-material",
    },
  ];

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1000,
          width: "100%",
          padding: "0 16px",
          background: colorBgContainer,
          display: "flex",
          justifyContent: "space-between", // Align items horizontally
          alignItems: "center", // Align items vertically
        }}
      >
        {/* First Part: Logo and Collapsed Button */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="demo-logo-vertical" style={{ width: "15%" }}>
            <img
              src={require("../../assest/New-logo-MITCA.webp")}
              alt="logo"
              style={{ marginRight: 16, width: "100%" }}
            />
          </div>
          <Button
            type="text"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined style={{ fontSize: "22px" }} />
              ) : (
                <MenuFoldOutlined style={{ fontSize: "22px" }} />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "22px",
              width: 64,
              height: 64,
              backgroundColor: "white",
            }}
          />
          <h5 style={{ fontSize: "22px" }}>ERP By Miracle Infosoft</h5>
        </div>

        {/* Third Part: Center Name and Logout Button */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{
              borderRadius: "3px",
              backgroundColor: "#0d6efd",
              color: "white",
            }}
          >
            <p style={{ color: "white" }}>ADMIN PANEL</p>
          </Button>
          <LogoutOutlined
            style={{
              fontSize: "22px",
              width: 35,
              height: 35,
              cursor: "pointer",
              transform: "rotate(-88deg)",
              borderRadius: "50px",
              backgroundColor: "#8b8bc6",
              marginLeft: "20px",
              padding: "6px",
              color: "white",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
            onClick={handleLogout}
          />
        </div>
      </Header>

      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          marginTop: 64,
          flexDirection: "column",
        }}
      >
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            overflowY: "auto",
            position: "fixed",
            height: "calc(100vh - 64px)",
            left: 0,
            top: 64,
            zIndex: 999,
          }}
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[location.pathname]}
            style={{ height: "100%", borderRight: 0 }}
          >
            {items.map((item) => (
              <Menu.Item key={item.link} icon={item.icon}>
                <Link to={item.link}>{item.label}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>

        {children}
      </Layout>
    </Layout>
  );
};

export default NavbarAdmin;
