import React from "react";
import styled, { keyframes } from "styled-components";

// const Container = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   display: flex;
// `;

// const rotateUno = keyframes`
//   0% {
//     transform: rotate(0deg);
//   }
//   25% {
//     transform: rotate(360deg);
//   }
//   30% {
//     transform: rotate(370deg);
//   }
//   35% {
//     transform: rotate(360deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// `;

// const rotateDos = keyframes`
//   0% {
//     transform: rotate(0deg);
//   }
//   20% {
//     transform: rotate(0deg);
//   }
//   30% {
//     transform: rotate(-180deg);
//   }
//   35% {
//     transform: rotate(-190deg);
//   }
//   40% {
//     transform: rotate(-180deg);
//   }
//   78% {
//     transform: rotate(-180deg);
//   }
//   95% {
//     transform: rotate(-360deg);
//   }
//   98% {
//     transform: rotate(-370deg);
//   }
//   100% {
//     transform: rotate(-360deg);
//   }
// `;

// const rotateTres = keyframes`
//   0% {
//     transform: rotate(0deg);
//   }
//   27% {
//     transform: rotate(0deg);
//   }
//   40% {
//     transform: rotate(180deg);
//   }
//   45% {
//     transform: rotate(190deg);
//   }
//   50% {
//     transform: rotate(180deg);
//   }
//   62% {
//     transform: rotate(180deg);
//   }
//   75% {
//     transform: rotate(360deg);
//   }
//   80% {
//     transform: rotate(370deg);
//   }
//   85% {
//     transform: rotate(360deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// `;

// const rotateCuatro = keyframes`
//   0% {
//     transform: rotate(0deg);
//   }
//   38% {
//     transform: rotate(0deg);
//   }
//   60% {
//     transform: rotate(-360deg);
//   }
//   65% {
//     transform: rotate(-370deg);
//   }
//   75% {
//     transform: rotate(-360deg);
//   }
//   100% {
//     transform: rotate(-360deg);
//   }
// `;

// const Dash = styled.div`
//   margin: 0 15px;
//   width: 35px;
//   height: 15px;
//   border-radius: 8px;
//   background: #FF2CBD;
//   box-shadow: 0 0 10px 0 #FECDFF;

//   &.uno {
//     margin-right: -18px;
//     transform-origin: center left;
//     animation: ${rotateUno} 3s linear infinite;
//   }

//   &.dos {
//     transform-origin: center right;
//     animation: ${rotateDos} 3s linear infinite;
//     animation-delay: .2s;
//   }

//   &.tres {
//     transform-origin: center right;
//     animation: ${rotateTres} 3s linear infinite;
//     animation-delay: .3s;
//   }

//   &.cuatro {
//     transform-origin: center right;
//     animation: ${rotateCuatro} 3s linear infinite;
//     animation-delay: .4s;
//   }
// `;
import { Spin } from "antd";
const LoadingSpinner = () => {
  return (
    //     <Container>
    //       <Dash className="dash uno" />
    //       <Dash className="dash dos" />
    //       <Dash className="dash tres" />
    //       <Dash className="dash cuatro" />
    //     </Container>

    <div className="example" style={{ position: "relative" }}>
      <Spin
        style={{
          position: "absolute",
          top: "-20px",
          left: 0,
          zIndex: 9999,
          backgroundColor: "white",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          display: "flex",
          alignItems:"center"
        }}
      />
    </div>
  );
};

export default LoadingSpinner;

// export default LoadingSpinner;
