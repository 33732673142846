import React from 'react'
import Navbar from '../component/navbar/Navbar'
import ExamticktFrom from '../component/examhallticat/ExamticktFrom'

const ExamHall = () => {
  return (
   <>
   <Navbar>
    <ExamticktFrom> </ExamticktFrom>
   </Navbar>
   </>
  )
}

export default ExamHall