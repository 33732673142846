import React, { useState, useEffect } from "react";
import { Button, Steps, Input, Row, message } from "antd";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
const { Step } = Steps;
const CreateAdmissionbyInquiry = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [date, setDate] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({
    studentid: "",
    email: "",
    fromnumber: "",
    cource: "",
    totalFees: "",
    discount: "",
    otherFees: "",
    lmsFees: "",
    name: "",
    dateofBrearth: "",
    gender: "",
    category: "",
    residenceAddress: "",
    permanentAddress: "",
    city: "",
    mobileNumber: "",
    parentsContact: "",
    degree: "",
    passingYear: "",
    university: "",
    courceType: "",
    durationCource: "",
    fatherName: "",
    specialization: "",
    remark: "",
    ragistationFees: "",
    feesBy: "",
    councellingBy: "",
    downPayment: "",
    installment: "",
    admissionDate: " ",
    downPaymentdate: "",
  });

  // Add state for inquiry data
  const [inquiryData, setInquiryData] = useState(null);

  // console.log(inquiryData)
  // console.log(inquiryData.inquri.name)
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = localStorage.getItem("auth-token");

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  useEffect(() => {
    // Fetch inquiry data when component mounts
    const fetchInquiryData = async () => {
      try {
        const inquiryResponse = await axios.get(
          `${apiUrl}/inquiry/get-inquri/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );

        const inquiryData = inquiryResponse.data;
        console.log("Inquiry Data:", inquiryData); // Console log the inquiry data
        // Set inquiryData state
        setInquiryData(inquiryData);
        // Update formData state with fetched inquiry data
      } catch (error) {
        console.error("API Error:", error);
        message.error(
          error.response?.data?.message ||
            "An error occurred while fetching inquiry data"
        );
      }
    };

    if (id) {
      fetchInquiryData();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "feesBy" && value === "PAP") {
      setFormData({
        ...formData,
        totalFees: "40000",
        lmsFees: "40000",
      });
    }
  };

  const handleSubmit = async () => {
    try {
      // Merge inquiry data with form data
      const mergedFormData = { ...formData, ...inquiryData.inquri };

      const response = await axios.post(
        `${apiUrl}/student/add-student`,
        mergedFormData,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      message.success("Admission Created Successfully");
      navigate("/admissiondata");
    } catch (error) {
      console.error("API Error:", error);
      message.error(error.response?.data?.message || "An error occurred");
    }
  };

  const calculateTotalFees = () => {
    const { totalFees, discount, otherFees } = formData;
    const calculatedTotal =
      parseFloat(totalFees || 0) -
      parseFloat(discount || 0) +
      parseFloat(otherFees || 0);

    return calculatedTotal.toFixed(2);
  };

  const calculateRemainingFees = () => {
    const totalFees = parseFloat(calculateTotalFees());
    const totalDownPayment = parseFloat(formData.downPayment || 0);
    const totalRegistrationFees = parseFloat(formData.ragistationFees || 0);
    const remainingFees = totalFees - totalDownPayment - totalRegistrationFees;
    return remainingFees.toFixed(2);
  };
  const installAmount = () => {
    const remainingFees = parseFloat(calculateRemainingFees());
    const installmentCount = parseInt(formData.installment);
    if (installmentCount <= 0) return 0; // Prevent division by zero or negative
    const installmentAmount = remainingFees / installmentCount;
    return installmentAmount.toFixed(0);
  };
  const next = () => setCurrent(current + 1);

  const prev = () => setCurrent(current - 1);
  const steps = [
    {
      title: "First",
      content: (
        <div>
          <h3 style={{ marginTop: "10px" }}>
            Step 1: Enter Student Personal Details{" "}
          </h3>
          <Row justify="space-around" display="flex">
            <Input
              placeholder="Student ID"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="studentid"
              value={formData.studentid}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="From Number "
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="fromnumber"
              value={formData.fromnumber}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Student Name"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="name"
              value={formData.name || (inquiryData && inquiryData.inquri.name)}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="mobile number"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="mobileNumber"
              value={
                formData.mobileNumber ||
                (inquiryData && inquiryData.inquri.mobileNumber)
              }
              onChange={handleChange}
            />
            <Input
              placeholder="Email ID"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="email"
              value={
                formData.email || (inquiryData && inquiryData.inquri.email)
              }
              onChange={handleChange}
            />
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <Input
              placeholder="Father Name"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="fatherName"
              value={
                formData.fatherName ||
                (inquiryData && inquiryData.inquri.fatherName)
              }
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Father Mobile Number"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="parentsContact"
              value={
                formData.parentsContact ||
                (inquiryData && inquiryData.inquri.mobileNumber)
              }
              onChange={handleChange}
              required
            />

            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
            >
              <option value="">Select Category</option>
              <option value="General">General</option>
              <option value="OBC">OBC</option>
              <option value="ST/SC">ST/SC</option>
            </select>
            <Input
              placeholder="Residence Address"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="residenceAddress"
              value={
                formData.residenceAddress ||
                (inquiryData && inquiryData.inquri.address)
              }
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Permanent Address"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="permanentAddress"
              value={
                formData.permanentAddress ||
                (inquiryData && inquiryData.inquri.address)
              }
              onChange={handleChange}
              required
            />

            <Input
              placeholder="City"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </Row>
        </div>
      ),
    },
    {
      title: "Second",
      content: (
        <div>
          <h3 style={{ marginTop: "10px" }}>
            Step 2: Enter Student Course Admission Details
          </h3>
          <Row justify="space-around" display="flex">
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="degree"
              value={
                formData.degree || (inquiryData && inquiryData.inquri.diploma)
              }
              onChange={handleChange}
              required
            >
              <option value={inquiryData && inquiryData.inquri.diploma}>
                Qulification
              </option>
              <option value="Under Graduate">Under Graduate</option>
              <option value="Graduate">Graduate</option>
              <option value="Post Graduate">Post Graduate</option>
            </select>
            <Input
              placeholder="Specialization"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="specialization"
              value={
                formData.specialization ||
                (inquiryData && inquiryData.inquri.specialization)
              }
              onChange={handleChange}
              required
            />
            <Input
              placeholder="University"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="university"
              value={
                formData.university ||
                (inquiryData && inquiryData.inquri.diplomauniversity)
              }
              onChange={handleChange}
              required
            />
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="passingYear"
              value={formData.passingYear}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Select a year
              </option>
              {Array.from({ length: 30 }, (_, index) => {
                const year = 1999 + index;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
            <Input
              placeholder="Course Name"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="cource"
              value={
                formData.cource || (inquiryData && inquiryData.inquri.cource)
              }
              onChange={handleChange}
              required
            />
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="courceType"
              value={formData.courceType}
              onChange={handleChange}
              required
            >
              <option value="">Course type</option>
              <option value="Career Course">Career Course</option>
              <option value="Module Course">Module Course</option>
              <option value="Internship">Internship</option>
              <option value="Magar Training">Magar Training</option>
              <option value="Field Projects">Field Projects</option>
            </select>

            {/* <Input
              placeholder="Duration"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="durationCource"
              value={formData.durationCource}
              onChange={handleChange}
              required
            /> */}
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="durationCource"
              value={formData.durationCource}
              onChange={handleChange}
              required
            >
              <option value="">Course Duraction</option>
              <option value="3 Months">3 Months</option>
              <option value="6 Months">6 Months</option>
              <option value="9 Months">9 Months</option>
              <option value="1 Years">1 Years</option>
              <option value="Other">Other</option>
            </select>

            <input
              placeholder="Enter DOB"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="date"
              name="dateofBirth"
              value={
                formData.dateofBrearth ||
                (inquiryData && inquiryData.inquri.dathofBirth[0])
              }
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Counseling By"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="councellingBy"
              value={
                formData.councellingBy ||
                (inquiryData && inquiryData.inquri.councellingBy)
              }
              onChange={handleChange}
              required
            />
          </Row>
        </div>
      ),
    },
    {
      title: "Last",
      content: (
        <div>
          <h3 style={{ marginTop: "10px" }}>
            Step 3: Enter Student Fees Details
          </h3>
          <Row justify="space-around" display="flex">
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="feesBy"
              value={formData.feesBy}
              onChange={handleChange}
              required
            >
              <option value="">Payment Model</option>
              <option value="PAT">PAT</option>
              <option value="PAP">PAP</option>
              <option value="Modular">Modular</option>
              <option value="Other">Other</option>
            </select>
            <Input
              placeholder="Fees"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="totalFees"
              value={formData.totalFees}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Pay After Placement"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="lmsFees"
              disabled={formData.feesBy !== "PAP"}
              value={formData.lmsFees}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Discount"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="discount"
              value={formData.discount}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Registration Fees"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="ragistationFees"
              value={formData.ragistationFees}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Down payment"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="downPayment"
              value={formData.downPayment}
              onChange={handleChange}
              required
            />

            <Input
              placeholder="Remaining Fees"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              value={calculateRemainingFees()}
              readOnly // Make it read-only
            />
            <select
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "8px",
                borderRadius: "5px",
              }}
              name="installment"
              value={formData.installment}
              onChange={handleChange}
              required
            >
              <option value="">Installment</option>
              <option value="1">1 </option>
              <option value="2">2 </option>
              <option value="3">3 </option>
              <option value="4">4 </option>
              <option value="5">5 </option>
              <option value="6">6 </option>
              <option value="7">7 </option>
              <option value="8">8 </option>
              <option value="9">9 </option>
              <option value="10">10 </option>
            </select>

            <Input
  placeholder={installAmount() > 0 ? "Installment Amount" : "Enter Installment Count"}
  style={{
    margin: "10px",
    width: "28%",
    marginTop: "20px",
    padding: "10px",
    borderRadius: "5px",
  }}
  name=""
  value={installAmount() > 0 ? installAmount() : ''}
  readOnly
/>

            {/* <input
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              placeholder="Admission Date"
              className="form-control"
              name="admissionDate"
              type={isFocused ? "date" : "text"}
              value={formData.admissionDate}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              id="date"
              required
            />
            */}
            <Input
              placeholder="Admission Date"
              style={{
                margin: "10px",
                width: "28%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              className="form-control"
              name="admissionDate"
              type="Date"
              value={formData.admissionDate}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Remark"
              style={{
                margin: "10px",
                width: "60%",
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
              }}
              name="remark"
              value={formData.remark}
              onChange={handleChange}
              required
            />
          </Row>
        </div>
      ),
    },
  ];

  return (
    <>
      <ToastContainer />
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div style={{ marginBottom: "24px" }}>
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div style={{ marginTop: "24px" }}>{steps[current].content}</div>
        <div style={{ marginTop: "24px" }}>
          {current < steps.length - 1 && (
            <Button type="primary" onClick={next}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={handleSubmit}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={prev}>
              Previous
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateAdmissionbyInquiry;
