import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import {
  Select,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreateLab = () => {
  const navigate = useNavigate(); // Call useNavigate as a function to get the navigation function

  const [labName, setLabName] = useState("");
  const [capability, setCapability] = useState("");
  const [labStatus, setLabStatus] = useState("");
  const [labFaclity, setLabFaclity] = useState("");

  const handleLabNameChange = (event) => {
    setLabName(event.target.value);
  };

  const handleCapabilityChange = (event) => {
    setCapability(event.target.value);
  };

  const handleLabStatusChange = (event) => {
    setLabStatus(event.target.value);
  };
  const handelLabFaclity = (event) => {
    setLabFaclity(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const authToken = localStorage.getItem("auth-token");

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/lab/create-lab`,
        {
          labName,
          capability,
          labFaclity,
          status: labStatus,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      console.log("Lab created successfully:", response.data);

      navigate("/find-lab"); // Navigate to the find lab page after successful creation
    } catch (error) {
      console.error("Error creating lab:", error);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <div
        className="col-sm-12 mb-3 mb-sm-0"
        style={{ margin: "auto", marginTop: "30px", width: "98%" }}
      >
        <div className="card w-100">
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="card-title" style={{ fontSize: "25px" }}>
              Create Lab
            </h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon
                style={{ fontSize: "15px", marginLeft: "13px" }}
              />
              Create Lab
            </h6>
          </div>
        </div>
      </div>

      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: "white",
          padding: "40px 10px 40px 10px ",
          margin: "auto",
          borderRadius: "10px",
          width: "98%",
        }}
      >
        <Grid item xs={4}>
          <TextField
            label="Lab Name"
            variant="outlined"
            fullWidth
            value={labName}
            onChange={handleLabNameChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Capability"
            variant="outlined"
            fullWidth
            value={capability}
            onChange={handleCapabilityChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Lab Faclity"
            variant="outlined"
            fullWidth
            value={labFaclity}
            onChange={handelLabFaclity}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={labStatus}
              onChange={handleLabStatusChange}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        onClick={handleSubmit}
        style={{ marginTop: "20px" }}
      >
        Create Lab
      </Button>
    </div>
  );
};

export default CreateLab;
