import React from 'react'
import Navbar from '../component/navbar/Navbar'
import GetAllpcDetails from '../component/utilites/GetAllpcDetails'

const FindAllLabPc = () => {
  return (
    <>
    <Navbar>
        <GetAllpcDetails></GetAllpcDetails>
    </Navbar>
    
    </>
  )
}

export default FindAllLabPc