import React from 'react'
import Navbar from "../component/navbar/Navbar"
import CreateInquri from '../component/inquripage/CreateInquri'
const InquriCreate = () => {
  return (
   <>
   <Navbar>
    <CreateInquri></CreateInquri>
   </Navbar>
   
   </>
  )
}

export default InquriCreate