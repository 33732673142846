import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styled from "styled-components";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const Button = styled.button`
  background-color: #1677ff;
  color: white;
  padding: 10px 20px;
  margin-bottom: 21px;
  margin-left: 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;

function MonthlyDownPaymentIncome() {
  const [startDate, setStartDate] = useState(new Date());
  const [income, setIncome] = useState(null);
  const [monthName, setMonthName] = useState("");
  const [error, setError] = useState(null);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [totalAmount, setTotalAmount] = useState(null);
  const [totalAdmissions, setTotalAdmissions] = useState(null);
  const [totalInquiry, setTotalInquiry] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const authToken = localStorage.getItem("auth-token");
      const selectedMonth = startDate.getMonth() + 1;
      const selectedYear = startDate.getFullYear();

      setYear(selectedYear);
      setMonth(selectedMonth);

      await fetchData(selectedMonth, selectedYear, authToken);
      setMonthName(getMonthName(selectedMonth));
    } catch (error) {
      setError(error.response);
    }
  };

  const fetchData = async (month, year, authToken) => {
    try {
      const [incomeResponse, totalAmountResponse, admissionsResponse, inquiryResponse] = await Promise.all([
        axios.get(`${apiUrl}/other/monthly-other-receptives/${year}/${month}`, { headers: { "auth-token": authToken } }),
        axios.get(`${apiUrl}/invoice/invoices/totalMonthlyAmount/${year}/${month}`, { headers: { "auth-token": authToken } }),
        axios.get(`${apiUrl}/student/monthly-admissions/${year}/${month}`, { headers: { "auth-token": authToken } }),
        axios.get(`${apiUrl}/inquiry/monthly-inquri/${year}/${month}`, { headers: { "auth-token": authToken } })
      ]);

      setIncome(incomeResponse.data.amount);
      setTotalAmount(totalAmountResponse.data.totalAmount);
      setTotalAdmissions(admissionsResponse.data.admissionCount);
      setTotalInquiry(inquiryResponse.data.inquriCount);
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const getMonthName = (monthNum) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[parseInt(monthNum) - 1];
  };

  useEffect(() => {
    if (year && month) {
      fetchData(month, year, localStorage.getItem("auth-token"));
    }
  }, [year, month]);

  return (
    <div style={{ padding: "30px" }}>
      <div className="col-sm-12 mb-3 mb-sm-0" style={{ margin: "auto", width: "98%", marginTop: "20px" }}>
        <div className="card w-100">
          <div className="card-body" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h4 className="card-title" style={{ fontSize: "25px" }}>
              Get Monthly Collection
            </h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon style={{ fontSize: "15px", marginLeft: "13px" }} />
              Monthly Collection
            </h6>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <h3 style={{ marginRight: "30px", marginLeft: "30px" }}>
          Select Month
        </h3>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          className="date-picker-monthly-collection"
        />
        <Button type="button" onClick={handleSubmit}>
          Search
        </Button>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                {totalInquiry}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Inquiry {monthName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                {totalAdmissions}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Admission {monthName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                {totalAmount}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Installment Fees {monthName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                {income}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Fresh Collection {monthName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default MonthlyDownPaymentIncome;
