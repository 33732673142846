import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import {
  Select,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  IconButton,
  Collapse,
} from "@mui/material"; // Import Material-UI components
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";

const CreateLab = () => {
  const [labName, setLabName] = useState("");
  const [capability, setCapability] = useState("");
  const [labStatus, setLabStatus] = useState("");
  const [open, setOpen] = useState(false);

  const handleLabNameChange = (event) => {
    setLabName(event.target.value);
  };

  const handleCapabilityChange = (event) => {
    setCapability(event.target.value);
  };

  const handleLabStatusChange = (event) => {
    setLabStatus(event.target.value);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div style={{ padding: "20px" }}>
      <div
        className="col-sm-12 mb-3 mb-sm-0"
        style={{ margin: "auto", marginTop: "30px", width: "98%" }}
      >
        <div className="card w-100">
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="card-title" style={{ fontSize: "25px" }}>
              Create Lab
            </h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon
                style={{ fontSize: "15px", marginLeft: "13px" }}
              />
              Create Lab
            </h6>
          </div>
        </div>
      </div>

      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: "white",
          padding: "40px 10px 40px 10px ",
          margin: "auto",
          borderRadius: "10px",
          width: "98%",
        }}
      >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <h4>Create Lab</h4>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleToggle}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={open}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label="Lab Name"
                      variant="outlined"
                      fullWidth
                      value={labName}
                      onChange={handleLabNameChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Capability"
                      variant="outlined"
                      fullWidth
                      value={capability}
                      onChange={handleCapabilityChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        labelId="status-label"
                        id="status-select"
                        value={labStatus}
                        onChange={handleLabStatusChange}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      fullWidth
                    >
                      Delete
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      startIcon={<UpdateIcon />}
                      fullWidth
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateLab;
