import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

function PCForm() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("auth-token");
  const { id, pcId } = useParams();
  const [formData, setFormData] = useState({
    pcName: "",
    caibinetName: "",
    prosserName: "",
    ram: "",
    hardDisk: "",
    monitarName: "",
    keyBoard: "",
    mouseName: "",
    remark: "",
  });

  useEffect(() => {
    // Fetch data if ID exists
    if (id && pcId) {
      axios.get(`${process.env.REACT_APP_API_URL}/labpc/single/${id}/${pcId}`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      })
        .then(response => {
          setFormData(response.data.labPC);
          console.log(response.data.labPC);
        })
        .catch(error => {
          console.log("Error fetching lab data: ", error);
        });
    }
  }, [id, pcId, authToken]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let response;
      if (id && pcId) {
        // Update existing lab entry
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}/labpc/update/${id}/${pcId}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );
      } else {
        // Add new lab entry
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/labpc/labs/${id}/labpcs`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );
      }
      
      console.log(response.data);
      navigate(`/get-lab-allpc/${id}`)
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <>
      <div
        className="col-sm-12 mb-3 mb-sm-0"
        style={{ margin: "auto", width: "96%", marginTop: "20px" }}
      >
        <div className="card w-100">
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="card-title" style={{ fontSize: "25px" }}>
              Add Lab List
            </h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon
                style={{ fontSize: "15px", marginLeft: "13px" }}
              />
              Lab List
            </h6>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "30px",
          backgroundColor: "white",
          margin: "15px",
          borderRadius: "10px",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl style={{width:"180px"}} >
                <InputLabel id="demo-select-small-label">PC Name</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={formData.pcName}
                  name="pcName"
                  label="PC Name"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {[...Array(12).keys()].map((index) => (
                    <MenuItem key={index} value={`PC${index + 1}`}>
                      {`PC${index + 1}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="caibinetName"
                label="Cabinet"
                variant="outlined"
                fullWidth
                value={formData.caibinetName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="prosserName"
                label="Processor"
                variant="outlined"
                fullWidth
                value={formData.prosserName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="ram"
                label="RAM"
                variant="outlined"
                fullWidth
                value={formData.ram}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="hardDisk"
                label="Hard Disk "
                variant="outlined"
                fullWidth
                value={formData.hardDisk}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="monitarName"
                label="Monitor"
                variant="outlined"
                fullWidth
                value={formData.monitarName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="keyBoard"
                label="Keyboard"
                variant="outlined"
                fullWidth
                value={formData.keyBoard}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="mouseName"
                label="Mouse"
                variant="outlined"
                fullWidth
                value={formData.mouseName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="remark"
                label="Remark"
                variant="outlined"
                fullWidth
                value={formData.remark}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ margin: "20px" }}
          >
            Submit
          </Button>
        </form>
      </div>
    </>
  );
}

export default PCForm;
