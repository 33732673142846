import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, message } from "antd";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { DatePicker, Select } from "antd";
import LoadingSpinner from "../lodingpage/LoadingPage";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const { RangePicker } = DatePicker;

const Container = styled.div`
  height: 288px;
  overflow: scroll;
  position: relative;
`;

const Table = styled.table`
  border: 0.01px solid rgba(0, 0, 0, 0.272);
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const TableCell = styled.td`
  border-bottom: 0.01px solid rgba(67, 66, 66, 0.636);
  height: 30px;
  padding: 8px 20px;
  font-size: 13px;
  margin-left: 20px; // Unnecessary margin
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconCell = styled.td`
  text-align: center;
`;

const ModalContainer = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid #888;
  width: 33%;
`;

const GetAllpcDetails = () => {
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false);
  const [getlabpc, setGetlabpc] = useState([]);
  const [loading, setLoading] = useState(true); // Initially set loading to true
  const { id } = useParams();
  const authToken = localStorage.getItem("auth-token");

  useEffect(() => {
    fetchData();
  }, [id]); // Include id in the dependency array

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `http://localhost:5000/api/labpc/get/${id}/labpcs`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      setGetlabpc(response.data);
     
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); // Set loading to false in case of error too
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleUpdatePC = async (pcId) => {
    navigate(`/lab-pc-from/${id}/${pcId}`)
  };

  const handleDeletePC = async (pcId) => {
    try {
      // Make DELETE request to delete API endpoint 
      const response = await axios.delete(
        `http://localhost:5000/api/labpc/delete/${id}/labpcs/${pcId}`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      // Handle successful response
      message.success("PC deleted successfully");
      fetchData();
      // Optionally, you can update the state or refetch data after deleting
    } catch (error) {
      console.error("Error deleting PC:", error);
      message.error("Failed to delete PC");
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            className="col-sm-12 mb-3 mb-sm-0"
            style={{ margin: "auto", width: "96%", marginTop: "20px" }}
          >
            <div className="card w-100">
              <div
                className="card-body"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h4 className="card-title" style={{ fontSize: "25px" }}>
                  LAB Details
                </h4>
                <h6 className="admissionf1">
                  <Link to="/dashboard">Home</Link>
                  <ArrowBackIosIcon
                    style={{ fontSize: "15px", marginLeft: "13px" }}
                  />{" "}
                  LAB
                </h6>
              </div>
            </div>
          </div>

          <Link to="/find-lab">
            <Button
              style={{
                margin: "5px",
                backgroundColor: "#5151d7",
                width: "200px",
                color: "white",
              }}
            >
              Show Lab
            </Button>
          </Link>
          <Container>
            <Table>
              <tbody>
                <TableRow className="first">
                  <TableCell />
                  <TableCell>ID</TableCell>
                  <TableCell> PC Name</TableCell>
                  <TableCell>Cabinet</TableCell>
                  <TableCell>Processor</TableCell>
                  <TableCell>RAM</TableCell>
                  <TableCell>hard Disk</TableCell>
                  <TableCell>Monitor</TableCell>
                  <TableCell>key Board</TableCell>
                  <TableCell>Mouse</TableCell>
                  <TableCell>Updated</TableCell>
                  <TableCell>Remark</TableCell>
                  <TableCell>Update PC/Delete PC</TableCell>
                  
                  <IconCell />
                </TableRow>
                {getlabpc.map((inquiry, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <RemoveRedEyeIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          openModal(inquiry);
                        }}
                      />
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{inquiry.pcName}</TableCell>
                    <TableCell>{inquiry.caibinetName}</TableCell>
                    <TableCell>{inquiry.prosserName}</TableCell>
                    <TableCell>{inquiry.ram}</TableCell>

                    <TableCell>{inquiry.hardDisk}</TableCell>
                    <TableCell>{inquiry.monitarName}</TableCell>
                    <TableCell>{inquiry.keyBoard}</TableCell>
                    <TableCell>{inquiry.mouseName}</TableCell>
                   
                    <TableCell>
                      {moment(inquiry.updatedAt).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{inquiry.remark}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleUpdatePC(inquiry._id)}>
                        Update PC
                      </Button>
                      <Button onClick={() => handleDeletePC(inquiry._id)}>Delete PC</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>

            <ModalContainer open={modalOpen} onClick={closeModal}>
              <ModalContent>
                <h2>Actions</h2>
                {/* Your modal content */}
              </ModalContent>
            </ModalContainer>
          </Container>
        </>
      )}
    </>
  );
};

export default GetAllpcDetails;
