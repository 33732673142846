const initialState = {
    authToken: null,
    user: null,
    loading: false,
    error: null,
    email: null,
    password: null,
  };
  
  export default initialState
  