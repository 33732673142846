import React from "react";
import LmsRecptive from "../component/lmsrecepit/LmsCreate";
import Navbar from "../component/navbar/Navbar";

const Lms = () => {
  return (
    <>
      <Navbar>
        <LmsRecptive> </LmsRecptive>
      </Navbar>
    </>
  );
};

export default Lms;  
