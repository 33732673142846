import React from 'react'
import Navbar from '../component/navbar/Navbar'
import BatchPage from '../component/batchpage/BatchPage'

const GetBatches = () => {
  return (
    <>
    <Navbar>
        <BatchPage></BatchPage>
    </Navbar>
    </>
  )
}

export default GetBatches