import React from 'react'
import Navbar from '../component/navbar/Navbar'
import CreateAdmissionbyInquriy from '../component/admission/CreateAdmissionbyInquriy'

const CaddmissionFinquiry = () => {
  return (
   <>
   <Navbar>
    <CreateAdmissionbyInquriy></CreateAdmissionbyInquriy>
   </Navbar>
   </>
  )
}

export default CaddmissionFinquiry