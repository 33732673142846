import React from 'react'
import Navbar from '../component/navbar/Navbar'
import CreateOthRecptive from '../component/other-receptiv/CreateRecptiv'

const OtherRecptiv = () => {
  return (
   <>
   <Navbar>
    <CreateOthRecptive></CreateOthRecptive>
   </Navbar>
   </>
  )
}

export default OtherRecptiv