import React from "react";
import Navbar from "../component/navbar/Navbar";
import BatchForm from "../component/batchpage/CreateBatch";

const CreateBatches = () => {
  return (
    <>
      <Navbar>
        <BatchForm></BatchForm>
      </Navbar>
    </>
  );
};

export default CreateBatches;
