import React from "react";
import Navbar from "../component/navbar/Navbar";
import RecpitData from "../component/recepitpage/RecpitData";

const Recepit = () => {
  return (
    <Navbar>
      <RecpitData>

      </RecpitData>
    </Navbar>
  );
};

export default Recepit;
