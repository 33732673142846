import React from 'react'
import Navbar from '../component/navbar/Navbar'
import ProfileCardGrid from '../component/faclityprofile/FaclityProfile'

const FaclityProfileCard = () => {
  return (
    <>
    <Navbar>
       <ProfileCardGrid></ProfileCardGrid> 
    </Navbar>
    </>
  )
}

export default FaclityProfileCard