import React, { useState, useEffect } from "react";
import styled from "styled-components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { DatePicker, Select } from "antd";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../lodingpage/LoadingPage";

const apiUrl = process.env.REACT_APP_API_URL;
const { Option } = Select;

const { RangePicker } = DatePicker;

const Container = styled.div`
  height: 288px;
  overflow: scroll;
  position: relative;
`;

const Table = styled.table`
  border: 0.01px solid rgba(0, 0, 0, 0.272);
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const TableCell = styled.td`
  border-bottom: 0.01px solid rgba(67, 66, 66, 0.636);
  height: 30px;
  padding: 8px 20px;
  font-size: 13px;
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconCell = styled.td`
  text-align: center;
`;

const ModalContainer = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid #888;
  width: 33%;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;

const GetOtherReceptive = () => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("auth-token");
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterType, setFilterType] = useState(""); // State for filter type
  const [dateRange, setDateRange] = useState([]);
  const [error, setError] = useState(null);
  const [totalInvoice, setTotalInvoice] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = (invoices) => {
    setSelectedRow(invoices);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedRow(null);
    setModalOpen(false);
  };

  const fetchData = async () => {
    try {
      if (!authToken) {
        console.error("No auth token found.");
        return;
      }

      const response = await axios.get(`${apiUrl}/other/get-otherrecptiv`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      setTotalInvoice(response.data); 
      console.log(response.data)
      // Corrected state name from 'setTotalinvice' to 'setTotalInvoice'
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const printInvoice = () => {
    if (selectedRow) {
      // Navigate using the ID of the selected invoices
      navigate(`/get-other-receptiv-design/${selectedRow._id}`);
    console.log(selectedRow._id)
    } else {
      console.error("No invoices selected.");
    }
  };

  return (
    <>
      <div
        className="col-sm-12 mb-3 mb-sm-0"
        style={{ margin: "auto", width: "96%", marginTop: "20px" }}
      >
        <div className="card w-100">
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="card-title" style={{ fontSize: "25px" }}>
              invoices List
            </h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon
                style={{ fontSize: "15px", marginLeft: "13px" }}
              />{" "}
              invoices
            </h6>
          </div>
        </div>
      </div>

      <Link to="/create-other-receptiv">
        <Button
          style={{
            margin: "5px",
            backgroundColor: "#5151d7",
            width: "200px",
            color: "white",
          }}
        >
          Create OTH invoices
        </Button>
      </Link>
      <Container>
        <Table>
          <tbody>
            <TableRow className="first">
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell>Student ID</TableCell>
              <TableCell>Invoice No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Method</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <IconCell />
            </TableRow>
            {totalInvoice &&
              totalInvoice.map((invoices, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <RemoveRedEyeIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        openModal(invoices);
                      }}
                    />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{invoices.studentRollNumber}</TableCell>
                  <TableCell>{invoices.invoiceNumber}</TableCell>
                  <TableCell>{invoices.studentName}</TableCell>
                  <TableCell>{invoices.amount}</TableCell>
                  <TableCell>{invoices.paymentMethod}</TableCell>
                  <TableCell>
                    {moment(invoices.date).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell style={{ color: "green" }}>Success</TableCell>
                </TableRow>
              ))}
          </tbody>
        </Table>

        <ModalContainer open={modalOpen} onClick={closeModal}>
          <ModalContent>
            <h2>Actions</h2>
            <Button onClick={printInvoice}>Print</Button>
          </ModalContent>
        </ModalContainer>
      </Container>
    </>
  );
};

export default GetOtherReceptive;
