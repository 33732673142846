import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../recepitpage/receptive.css";
import numWords from "num-words";
import { Button } from "@progress/kendo-react-buttons";
import { PDFExport } from "@progress/kendo-react-pdf";
import LoadingSpinner from "../lodingpage/LoadingPage";
import moment from "moment";

const OtherInvoiceDegin = () => {
  const amountInWords = (number) => {
    return numWords(number);
  };
  const [invoiceData, setInvoiceData] = useState([]);
  const [userAdders, setUserAdders] = useState();
  const [error, setError] = useState(null);
  const { id } = useParams();
  const pdfExportComponent = useRef(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("auth-token");
        const response = await axios.get(
          `${apiUrl}/other/get-otherrecptiv/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );

        setInvoiceData(response.data);
        setTotalAmount(response.data.amount);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [id]);
  useEffect(() => {
    const fetchUserData = async () => {
      const authToken = localStorage.getItem("auth-token");

      if (authToken) {
        try {
          const response = await axios.post(
            `${apiUrl}/auth/getuser`,
            {}, // empty data object since it's a POST request
            {
              headers: {
                "Content-Type": "application/json",
                "auth-token": authToken,
              },
            }
          );
          // Handle successful response
          setUserAdders(response.data.address);
          console.log(response.data);
        } catch (error) {
          // Handle error
          if (error.response && error.response.status === 401) {
            console.error(
              "Unauthorized: Please authenticate using a valid token."
            );
            // Handle authentication error (e.g., redirect to login page)
          } else {
            console.error("Error fetching user data:", error);
          }
        }
      }
    };

    fetchUserData(); // Call the fetchUserData function
  }, []); // Empty dependency array means this useEffect will only run once, similar to componentDidMount

  // Moved calculations dependent on totalAmount inside the component bod

  const handleExportWithComponent = () => {
    pdfExportComponent.current.save();
  };
  const [layoutSelection, setLayoutSelection] = useState({
    text: "A4",
    value: "size-a4",
  });

  return (
    <div>
      <div>
        <div>
          <div>
            <div id="example">
              <div className="box wide hidden-on-narrow"></div>
              <div className="page-container hidden-on-narrow">
                <PDFExport ref={pdfExportComponent}>
                  <div className={`pdf-page ${layoutSelection.value}`}>
                    <div className="main-page">
                      <div className="sub-page">
                        <div
                          className="addreshcontainer"
                          style={{ border: "2px solid blue" }}
                        >
                          <div
                            className="myinvoiceData"
                            style={{ padding: "5px", display: "flex" }}
                          >
                            <div
                              className="myinvoiceData1"
                              style={{ width: "50%" }}
                            >
                              <img
                                src={require("../../assest/rrlogoit.png")} style={{width:"87%"}}
                                alt="miracle logo"
                              />
                            </div>
                            <div
                              className="myinvoiceData2"
                              style={{
                                width: "92%",
                                justifyContent: "space-between",
                                display: "flex",
                                marginBottom: "13px",
                              }}
                            >
                              <div
                                className="myinvoiceData3"
                                style={{
                                  justifyContent: "space-between",
                                }}
                              >
                                <h2
                                  style={{
                                    textDecoration: "underline",
                                    fontSize: "10px",
                                  }}
                                >
                                  Receipt
                                </h2>
                                <h4
                                  style={{
                                    fontSize: "10px",
                                    // marginLeft: "54px",
                                    marginTop: "-9px",
                                    marginBottom: "3px",
                                  }}
                                >
                                  Service Provider : <br /> Miracle IT Career
                                  Acedemy
                                  <br /> {userAdders}
                                </h4>
                              </div>
                              <div className="myinvoiceData3">
                                <p
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "end",
                                  }}
                                >
                                  Center Copy <br /> Receipt No:
                                  {invoiceData.invoiceNumber}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="myinvoiceData"
                            style={{ display: "flex" }}
                          >
                            <div
                              className="invoiceData3"
                              style={{ width: "50%" }}
                            />
                            <div
                              className="invoiceData3"
                              style={{ width: "60%" }}
                            >
                              <p
                                style={{
                                  marginTop: "-59px",
                                  marginBottom: 0,
                                  fontSize: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "10px",
                                    width: "21vw",
                                    marginTop: "18px",
                                    lineHeight: "12px",
                                  }}
                                >
                                  {/* Akriti complex mp nagar Bhopal */}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            borderTop: "none",
                            borderBottom: "none",
                            // borderBottom:"none",
                            marginTop: "-18px",
                            display: "flex",
                            border: "2px solid blue",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            Student Name : {invoiceData.studentName}
                          </h3>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            borderTop: 0,
                            border: "2px solid blue",
                            // borderTop: "none",
                            padding: 5,
                            justifyContent: "space-between",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              fontSize: "10px",
                              fontWeight: "500",
                            }}
                          >
                            Invoice Types : {invoiceData.receptiType}
                          </h3>
                          <h3
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              fontSize: "10px",
                              fontWeight: "500",
                            }}
                          >
                            Cource : {invoiceData.cource}
                          </h3>
                          <h3
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              fontSize: "10px",
                              fontWeight: "500",
                            }}
                          >
                            Date :{" "}
                            {moment(invoiceData.date).format("DD/MM/YYYY")}
                          </h3>
                        </div>

                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%", textAlign: "center" }}
                          >
                            <h3
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "12px",
                              }}
                            >
                              Particular
                            </h3>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                              textAlign: "center",
                            }}
                          >
                            <h3
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "12px",
                              }}
                            >
                              Amount
                            </h3>
                          </div>
                        </div>

                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%" }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              Course Fees [SAC:00440229]
                            </p>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                            }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              {totalAmount}
                            </p>
                          </div>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%" }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              CGST @9%
                            </p>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                            }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              0
                            </p>
                          </div>
                        </div>

                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%" }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              CGST @9%
                            </p>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                            }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              0
                            </p>
                          </div>
                        </div>

                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%", textAlign: "end" }}
                          >
                            <p
                              style={{
                                marginRight: " 7px",
                                fontWeight: "bold",
                                marginTop: 0,
                                fontSize: "12px",
                                marginBottom: 0,
                              }}
                            >
                              Total Fees Paid
                            </p>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                              fontWeight: "bold",
                            }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "12px",
                              }}
                            >
                              {totalAmount}/-
                            </p>
                          </div>
                        </div>

                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%" }}
                          >
                            <p
                              style={{
                                padding: 3,
                                borderBottom: "2px solid blue",
                                fontSize: "10px",
                                margin: 0,
                              }}
                            >
                              Amount in Words: {amountInWords(totalAmount)}
                            </p>
                            <p
                              style={{
                                padding: 3,
                                // borderBottom: "2px solid blue",
                                fontSize: "10px",
                                margin: 0,
                              }}
                            >
                              Paid By : {invoiceData.paymentMethod}
                            </p>
                            {/* <p
                            style={{ padding: 3, margin: 0, fontSize: "10px" }}
                          >
                            Date :{invoiceData.MobileNo}
                          </p> */}
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                            }}
                          >
                            <p style={{ margin: "auto", fontSize: "10px" }}>
                              Authorised signature /-
                            </p>
                          </div>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderBottom: 0,
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "50%", textAlign: "left" }}
                          >
                            <h3
                              style={{
                                marginTop: 0,
                                marginBottom: "13px",
                                marginLeft: 5,
                                fontSize: "1rem",
                                fontSize: "12px",
                              }}
                            >
                              Student Signature:
                            </h3>
                          </div>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ textAlign: "left" }}
                          >
                            <p
                              className="tramsline"
                              style={{
                                marginTop: 0,
                                marginBottom: 8,
                                marginLeft: 5,
                                fontSize: "10px",
                              }}
                            >
                              1) Any issues/legal matters are subject to Bhopal
                              jurisdiction.
                              <br /> 2) Cheque / D.D in Favor Of Miracle
                              Information Services Pvt. Ltd. <br />
                              3) Certified that the particulars given in the
                              invoiceData are true and correct according to best
                              of our belief and knowledge.
                              <br />
                              4) Booking, Registration, Admission and
                              Installment Fee once paid is Non-refundable.
                            </p>
                          </div>
                        </div>

                        <div
                          style={{
                            border: "2px dotted #3498db",
                            marginTop: 4,
                            marginBottom: 4,
                          }}
                        />
                        <div
                          className="addreshcontainer"
                          style={{ border: "2px solid blue" }}
                        >
                          <div
                            className="myinvoiceData"
                            style={{ padding: "5px", display: "flex" }}
                          >
                            <div
                              className="myinvoiceData1"
                              style={{ width: "50%" }}
                            >
                              <img
                              src={require("../../assest/rrlogoit.png")}
                              alt="miracle-logo"
                              style={{ width: "87%" }}
                            />
                            </div>

                            <div
                              className="myinvoiceData2"
                              style={{
                                width: "92%",
                                justifyContent: "space-between",
                                display: "flex",
                                // marginTop: "13px",
                              }}
                            >
                              <div
                                className="myinvoiceData3"
                                style={{
                                  justifyContent: "space-between",
                                }}
                              >
                                <h2
                                  style={{
                                    textDecoration: "underline",
                                    fontSize: "10px",
                                  }}
                                >
                                  Receipt
                                </h2>
                                <h4
                                  style={{
                                    fontSize: "10px",
                                    // marginLeft: "54px",
                                    marginTop: "-9px",
                                    marginBottom: "3px",
                                  }}
                                >
                                  Service Provider : <br /> Miracle IT Career
                                  Acedemy
                                  <br /> {userAdders}
                                </h4>
                              </div>
                              <div
                                className="myinvoiceData3"
                                style={{ marginTop: "-2px" }}
                              >
                                <p
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "end",
                                    marginTop: "0px",
                                  }}
                                >
                                  Student Copy <br /> Receipt No:
                                  {invoiceData.invoiceNumber}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            className="myinvoiceData"
                            style={{ display: "flex" }}
                          >
                            <div
                              className="invoiceData3"
                              style={{ width: "50%" }}
                            />
                            <div
                              className="invoiceData3"
                              style={{ width: "60%" }}
                            >
                              <p
                                style={{
                                  marginTop: "-59px",
                                  marginBottom: 0,
                                  fontSize: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "10px",
                                    width: "21vw",
                                    marginTop: "29px",
                                    lineHeight: "12px",
                                  }}
                                >
                                  {/* Akriti complex mp nagar Bhopal */}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            borderTop: "none",
                            // borderBottom:"none",
                            display: "flex",
                            marginTop: "-2px",
                            borderBottom: "none",
                            border: "2px solid blue",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            Student Name : {invoiceData.studentName}
                          </h3>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            borderTop: 0,
                            border: "2px solid blue",
                            // borderTop: "none",
                            padding: 5,
                            justifyContent: "space-between",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              fontSize: "10px",
                              fontWeight: "500",
                            }}
                          >
                            Invoices Types :  {invoiceData.receptiType}
                          </h3>
                          <h3
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              fontSize: "10px",
                              fontWeight: "500",
                            }}
                          >
                            Cource : {invoiceData.cource}
                          </h3>
                          <h3
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              fontSize: "10px",
                              fontWeight: "500",
                            }}
                          >
                            Date :
                            {moment(invoiceData.date).format("DD/MM/YYYY")}
                          </h3>
                        </div>

                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%", textAlign: "center" }}
                          >
                            <h3
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "12px",
                              }}
                            >
                              Particular
                            </h3>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                              textAlign: "center",
                            }}
                          >
                            <h3
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "12px",
                              }}
                            >
                              Amount
                            </h3>
                          </div>
                        </div>

                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%" }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              Course Fees [SAC:00440229]
                            </p>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                            }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              {totalAmount}
                            </p>
                          </div>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%" }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              CGST @9%
                            </p>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                            }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              0
                            </p>
                          </div>
                        </div>

                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%" }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              CGST @9%
                            </p>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                            }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "10px",
                              }}
                            >
                              0
                            </p>
                          </div>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%", textAlign: "end" }}
                          >
                            <p
                              style={{
                                marginRight: "7px",
                                fontWeight: "bold",
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "12px",
                              }}
                            >
                              Total Fees Paid
                            </p>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                              fontWeight: "bold",
                            }}
                          >
                            <p
                              style={{
                                marginLeft: 3,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "12px",
                              }}
                            >
                              {invoiceData.amount}/-
                            </p>
                          </div>
                        </div>

                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "70%" }}
                          >
                            <p
                              style={{
                                padding: 3,
                                borderBottom: "2px solid blue",
                                fontSize: "10px",
                                margin: 0,
                              }}
                            >
                              Amount in Words: {amountInWords(totalAmount)}
                            </p>
                            <p
                              style={{
                                padding: 3,
                                // borderBottom: "2px solid blue",
                                fontSize: "10px",
                                margin: 0,
                              }}
                            >
                              Paid By : {invoiceData.paymentMethod}
                            </p>
                          </div>
                          <div
                            className="myinvoiceData01"
                            style={{
                              width: "30%",
                              borderLeft: "1px solid blue",
                            }}
                          >
                            <p style={{ margin: "auto", fontSize: "10px" }}>
                              Authorised signature /-
                            </p>
                          </div>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderBottom: 0,
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ width: "50%", textAlign: "left" }}
                          >
                            <h3
                              style={{
                                marginTop: 0,
                                marginBottom: "13px",
                                marginLeft: 5,
                                fontSize: "1rem",
                                fontSize: "12px",
                              }}
                            >
                              Student Signature:
                            </h3>
                          </div>
                        </div>
                        <div
                          className="myinvoiceData"
                          style={{
                            display: "flex",
                            border: "2px solid blue",
                            borderTop: 0,
                          }}
                        >
                          <div
                            className="myinvoiceData01"
                            style={{ textAlign: "left" }}
                          >
                            <p
                              className="tramsline"
                              style={{
                                marginTop: 0,
                                marginBottom: 8,
                                marginLeft: 5,
                                fontSize: "10px",
                              }}
                            >
                              1) Any issues/legal matters are subject to Bhopal
                              jurisdiction.
                              <br /> 2) Cheque / D.D in Favor Of Miracle
                              Information Services Pvt. Ltd. <br />
                              3) Certified that the particulars given in the
                              invoiceData are true and correct according to best
                              of our belief and knowledge.
                              <br />
                              4) Booking, Registration, Admission and
                              Installment Fee once paid is Non-refundable.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </PDFExport>
              </div>
              <div
                className="box-col"
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
              >
                <Button
                  primary={true}
                  style={{
                    backgroundColor: "white",
                    padding: "12px",
                    borderRadius: "10px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                  onClick={handleExportWithComponent}
                >
                  Export
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherInvoiceDegin;
