import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
function FindLab() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const authToken = localStorage.getItem("auth-token");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lab/get-lab`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      setData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (id) => {
    console.log("Edit clicked for ID:", id);
    // Add your edit logic here, such as navigating to an edit page
  };
  const handleAdd = (id) => {
    // console.log("Edit clicked for ID:", id);
    navigate(`/lab-pc-from/${id}`);
    // Add your edit logic here, such as navigating to an edit page
  };
  const handelViewAllPC = (id) => {
    navigate(`/get-lab-allpc/${id}`);
    console.log("View all PC",id)
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/lab/delete-lab/${id}`, {
        headers: {
          "auth-token": authToken,
        },
      });
      console.log("Delete successful for ID:", id);
      fetchData(); // Fetch data again after successful deletion
    } catch (error) {
      console.error("Error deleting item:", error);
      // Handle any errors, such as displaying an error message to the user
    }
  };
  const buttonStyle = {
    width: "200px",
    margin: "auto",
    display: "block",
  };

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <Link to="/create-lab">
          <Button variant="contained" style={buttonStyle}>
            Create LAB
          </Button>
        </Link>
      </div>

      <Box display="flex" flexDirection="row" padding="30px" gap={2}>
        {data.map((item, index) => (
          <Card key={index}>
            <CardContent
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <Typography variant="h5" component="div">
                  {item.labName}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Lab Capability: {item.capability}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Lab Faclity: {item.labFaclity}
                </Typography>
                <Typography variant="body2">
                  Lab Status: {item.status}
                </Typography>
                <Button
                  variant="outlined"
                  style={{ marginTop: "10px" }}
                  onClick={() => handelViewAllPC(item._id)}
                >
                 View more
                </Button>
              </div>
              <div>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleEdit(item._id)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(item._id)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  aria-label="add"
                  onClick={() => handleAdd(item._id)}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>
    </>
  );
}

export default FindLab;
