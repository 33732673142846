import React, { useState, useEffect } from "react";
import styled from "styled-components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { DatePicker, Select, Button } from "antd";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../lodingpage/LoadingPage";

const { Option } = Select;

const { RangePicker } = DatePicker;

const Container = styled.div`
  height: 288px;
  overflow: scroll;
  position: relative;
`;

const Table = styled.table`
  border: 0.01px solid rgba(0, 0, 0, 0.272);
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const TableCell = styled.td`
  border-bottom: 0.01px solid rgba(67, 66, 66, 0.636);
  height: 30px;
  padding: 8px 20px;
  font-size: 13px;
  margin-left: 20px; // Unnecessary margin
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconCell = styled.td`
  text-align: center;
`;

const ModalContainer = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid #888;
  width: 33%;
`;

const InquiryData = () => {
  const authToken = localStorage.getItem("auth-token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [error, setError] = useState(null);
  const [totalInquiry, setTotalInquiry] = useState({
    success: false,
    totalCount: 0,
    message: "",
    inquiries: [],
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      if (!authToken) {
        console.error("No auth token found.");
        return;
      }

      const response = await axios.get(`${apiUrl}/inquiry/get-inquri`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      setTotalInquiry(response.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFilter = async () => {
    try {
      let startDate = "",
        endDate = "";
      if (dateRange.length === 2) {
        startDate = dateRange[0].format("YYYY-MM-DD");
        endDate = dateRange[1].format("YYYY-MM-DD");
      }
      let url = "";
      switch (filterType) {
        case "Date":
          url = `${apiUrl}/inquiry/inquiries/betweenDates/${startDate}/${endDate}`;
          break;
        case "Directly":
        case "Social Media":
        case "Telecaller":
          url = `${apiUrl}/inquiry/inquiries/betweenDatesBySource/${startDate}/${endDate}/${filterType}`;
          break;
        default:
          return;
      }
      const response = await axios.get(url,{
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      setTotalInquiry({
        ...totalInquiry,
        success: response.data.success,
        totalCount: response.data.totalCount,
        message: response.data.message,
        inquiries: response.data.inquiries,
      });
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const openModal = (inquiry) => {
    setSelectedRow(inquiry);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedRow(null);
    setModalOpen(false);
  };

  const handleUpdateProfile = () => {
    if (selectedRow) {
      // Navigate using the ID of the selected inquiry
      navigate(`/create-inquri/${selectedRow._id}`);
    } else {
      console.error("No inquiry selected.");
    }
  };
  const handleTakeAdmission = () => {
    if (selectedRow) {
      // Navigate using the ID of the selected inquiry
      navigate(`/create-admission-inquiry/${selectedRow._id}`);
    } else {
      console.error("No inquiry selected.");
    }
  };

  if (!totalInquiry.success) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div
        className="col-sm-12 mb-3 mb-sm-0"
        style={{ margin: "auto", width: "96%", marginTop: "20px" }}
      >
        <div className="card w-100">
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="card-title" style={{ fontSize:"25px"}}>
              Inquiry List
            </h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon
                style={{ fontSize: "15px", marginLeft: "13px" }}
              />
              Inquiry
            </h6>
          </div>
        </div>
      </div>
      <div
        className="col-sm-12 mb-3 mb-sm-0"
        style={{ margin: "auto", width: "96%", marginTop: "10px" }}
      >
        <div className="card w-100">
          <div className="card-body">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <div>
                <Select
                  defaultValue="Select by Filter"
                  style={{
                    marginLeft: "10px",
                    textAlign: "justify",
                    height: "39px",
                  }}
                  onChange={(value) => setFilterType(value)}
                >
                  <Option value="Date">Date</Option>
                  <Option value="Directly">Directly</Option>
                  <Option value="Social Media">Social-Media</Option>
                  <Option value="Telecaller">Telecallers</Option>
                </Select>
              </div>
              <div style={{ marginLeft: "10px" }}>
                Date Range
                <RangePicker
                  style={{ marginLeft: "20px" }}
                  onChange={(dates) => setDateRange(dates)}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Button type="primary" onClick={handleFilter}>
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link to="/create-inquri">
        <Button
          style={{
            margin: "5px",
            backgroundColor: "#5151d7",
            width: "200px",
            color: "white",
          }}
        >
          Create Inquiry
        </Button>
      </Link>
      <Container>
        <Table>
          <tbody>
            <TableRow className="first">
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile No.</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Telecaller</TableCell>
              <TableCell>Counselor</TableCell>
              <TableCell>Course Type</TableCell>
              <TableCell>Status</TableCell>
              <IconCell />
            </TableRow>
            {totalInquiry.inquiries &&
              totalInquiry.inquiries.map((inquiry, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <RemoveRedEyeIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        openModal(inquiry);
                      }}
                    />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{inquiry.name}</TableCell>
                  <TableCell>{inquiry.email}</TableCell>
                  <TableCell>{inquiry.mobileNumber}</TableCell>
                  <TableCell>{inquiry.cource}</TableCell>
                  <TableCell>
                    {moment(inquiry.inquriDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>{inquiry.source}</TableCell>
                  <TableCell>{inquiry.telicaller}</TableCell>
                  <TableCell>{inquiry.counclingby}</TableCell>
                  <TableCell>{inquiry.courceType}</TableCell>
                  <TableCell>{inquiry.status}</TableCell>
                </TableRow>
              ))}
          </tbody>
        </Table>

        <ModalContainer open={modalOpen} onClick={closeModal}>
          <ModalContent>
            <h2>Actions</h2>
            <Button onClick={handleUpdateProfile}>Update Profile</Button>
            <Button onClick={handleTakeAdmission}>Admission</Button>
          </ModalContent>
        </ModalContainer>
      </Container>
    </>
  );
};

export default InquiryData;
