import React from "react";
import { Grid, Card, CardContent, Typography, makeStyles } from "@mui/material";
import axios from "axios"; // Assuming you're using Axios for HTTP requests
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
const AdminInsideCard = () => {
  const { id } = useParams();
  console.log(id);
  
  return (
    <div style={{ margin: "2rem" }}>
      <Grid container spacing={2} justifyContent="center">
        {[
          { type: "inquiry", text: "Inquiry" },
          { type: "admission", text: "Admission" },
          { type: "collection", text: "Collection" },
        ].map((card) => (
          <Grid item xs={12} sm={6} md={4} key={card.type}>
            <Link to={`/admin-insidecard/${card.type}/${id}`}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {card.text}
                  </Typography>
                  <Typography color="textSecondary">
                    Click to fetch {card.text.toLowerCase()} data
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};


export default AdminInsideCard;
