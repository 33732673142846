import React from "react";
import Navbar from "../component/navbar/Navbar";
import CreateFaclityProfile from "../component/faclityprofile/CreateFaclityProfile";

const CreateFaclity = () => {
  return (
    <>
      <Navbar>
        <CreateFaclityProfile></CreateFaclityProfile>
      </Navbar>
    </>
  );
};

export default CreateFaclity;
