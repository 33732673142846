import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px 0;
`;

const Card = styled.div`
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
`;

const Box = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;

  &:hover {
    transform: translateY(-50px);
  }
`;

const BoxContent = styled.div`
  padding: 20px;
  text-align: center;
`;

const H2 = styled.h2`
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
`;

const H3 = styled.h3`
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
`;

const P = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  margin-top: 10px;
  transition: 0.5s;
`;

const A = styled.a`
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
    background: #fff;
    color: #000;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  padding: 20px;
  border-radius: 10px;
`;
const Button = styled.button`
  background-color: blue;
  padding: 8px;
  width: 114px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darkblue;
  }
`;

// Define fetchUserData function outside of the component
const fetchUserData = async (setUserName, setLoading, apiUrl) => {
  const authToken = localStorage.getItem("auth-token");

  if (authToken) {
    try {
      const response = await axios.post(
        `${apiUrl}/auth/getuser`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      // Handle successful response
      setUserName(response.data.allUsers);
      console.log(response.data.allUsers);
    } catch (error) {
      // Handle error
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized: Please authenticate using a valid token.");
        // Handle authentication error (e.g., redirect to login page)
      } else {
        console.error("Error fetching user data:", error);
      }
    } finally {
      // Set loading state to false
      setLoading(false);
    }
  }
};

const CardComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null); // State to store the ID of the user to delete
  const navigate = useNavigate();
  const [userName, setUserName] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchUserData(setUserName, setLoading, apiUrl); // Call the fetchUserData function
  }, []);
  // Empty dependency array means this useEffect will only run once, similar to componentDidMount
  const handleViewMoreClick = (id) => {
    navigate(`/admin-insidecard/${id}`);
    console.log("User ID:", id);
    // You can add any additional logic here, such as navigating to another page
  };

  const handleDeleteClick = (id) => {
    setUserIdToDelete(id); // Store the ID of the user to delete
    setShowModal(true);
  };

  const handleDeleteConfirm = async () => {
    const authToken = localStorage.getItem("auth-token");
    try {
      await axios.delete(`${apiUrl}/auth/deleteuser/${userIdToDelete}`, {
        headers: {
          "auth-token": authToken,
        },
      });

      console.log("User Delete Successfully ");
      fetchUserData(setUserName, setLoading, apiUrl); // Refetch user data after deletion
    } catch (error) {
      console.log(error);
    }

    // Now you can access the ID of the user to delete using the userIdToDelete state
    console.log("User delete API call for user ID:", userIdToDelete);
    // Agar aapko modal band karna hai:
    setShowModal(false);
  };

  const handleCancelClick = () => {
    // Agar user cancel karta hai toh modal ko band kar do
    setShowModal(false);
  };

  return (
    <Body>
      <Container>
        {Array.isArray(userName) && userName.length > 0 ? (
          userName.map((card, index) => (
            <Card key={card.id}>
              <Box>
                <BoxContent>
                  <H2>{index + 1}</H2>
                  <H3>{card.name}</H3>
                  <P>{card.email}</P>
                  <P>{card.address}</P>

                  <A onClick={() => handleViewMoreClick(card._id)}>View More</A>
                  <A
                    onClick={() => handleDeleteClick(card._id)}
                    style={{ margin: "10px" }}
                  >
                    Delete User
                  </A>
                </BoxContent>
              </Box>
            </Card>
          ))
        ) : (
          <p>No users found.</p>
        )}
      </Container>
      {showModal && (
        <Modal>
          <ModalContent>
            <h4>
              <strong style={{ color: "red" }}>Alert</strong> Do you want to
              delete user?
            </h4>
            <div
              className="buttons"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button onClick={handleDeleteConfirm}>Yes</Button>
              <Button onClick={handleCancelClick}>No</Button>
            </div>
          </ModalContent>
        </Modal>
      )}
    </Body>
  );
};

export default CardComponent;
