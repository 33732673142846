import React from "react";
import NavbarAdmin from "../component/adminDasboard/NavbarAdmin";
import AdminDasboard from "../component/adminDasboard/AdminDasboard";

const AdminDasbordPage = () => {
  return (
    <>
      <NavbarAdmin>
        <AdminDasboard></AdminDasboard>
      </NavbarAdmin>
    </>
  );
};

export default AdminDasbordPage;
