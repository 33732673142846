import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; // Import Axios
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Grid,
} from "@mui/material"; // Import Material-UI components

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ExamticktFrom = () => {
  const [formData, setFormData] = useState({
    type: "",
    receiptNumber: "",
    subject: "",
    facilityName: "",
    batchTime: "",
    date: "",
    studentid: "",
    studentName: "",
    regularFees: "",
    particulars: "",
    remarks: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make POST request to your API endpoint
      const response = await axios.post("your_api_endpoint_here", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Add Authorization header with token
        },
      });
      console.log(response.data); // Log response from server
      // You can handle success response here
    } catch (error) {
      console.error("Error:", error);
      // You can handle error response here
    }
  };
  return (
    <>
      <div
        className="col-sm-12 mb-3 mb-sm-0"
        style={{ margin: "auto", width: "96%", marginTop: "30px" }}
      >
        <div className="card w-100">
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="card-title" style={{ fontSize: "25px" }}>
              Create Exam Hall Tikat
            </h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon
                style={{ fontSize: "15px", marginLeft: "13px" }}
              />
              Exam Hall Tikat
            </h6>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          style={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
            margin: "14px",
            width: "96%",
          }}
        >
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                id="type-select"
                name="type"
                value={formData.type}
                onChange={handleChange}
              >
                <MenuItem value="Regular">Regular</MenuItem>
                <MenuItem value="Private">Private</MenuItem>
                {/* Add more MenuItem components for additional options */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Subject</InputLabel>
              <Select
                labelId="type-label"
                id="type-select"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
              >
                <MenuItem value="Regular">Regular</MenuItem>
                <MenuItem value="Private">Private</MenuItem>
                {/* Add more MenuItem components for additional options */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Hall Ticket No"
              name="receiptNumber"
              value={formData.receiptNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              type="date"
              label="Date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Student ID</InputLabel>
              <Select
                labelId="type-label"
                id="type-select"
                name="studentid"
                value={formData.studentid}
                onChange={handleChange}
              >
                <MenuItem value="Regular">Regular</MenuItem>
                <MenuItem value="Private">Private</MenuItem>
                {/* Add more MenuItem components for additional options */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Student Name"
              name="studentName"
              value={formData.studentName}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Faculity Name"
              name="receiptNumber"
              value={formData.receiptNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Faculity</InputLabel>
              <Select
                labelId="type-label"
                id="type-select"
                name="facilityName"
                value={formData.facilityName}
                onChange={handleChange}
              >
                <MenuItem value="Regular">Regular</MenuItem>
                <MenuItem value="Private">Private</MenuItem>
                {/* Add more MenuItem components for additional options */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Raguler Fees"
              name="regularFees"
              value={formData.regularFees}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Particulars"
              name="particulars"
              value={formData.particulars}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Remarks"
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ExamticktFrom;
