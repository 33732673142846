import React from "react";
import Navbar from "../component/navbar/Navbar";
import GetOtherReceptive from "../component/other-receptiv/GetReceptive";

const GetOtherInvoice = () => {
  return (
    <>
      <Navbar>
        <GetOtherReceptive></GetOtherReceptive>
      </Navbar>
    </>
  );
};

export default GetOtherInvoice;
