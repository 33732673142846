import React, { useEffect, useState } from "react";
import axios from "axios";

const Inquri_AdmissionCard = () => {
  const [userIds] = useState(["660e72c71cfc958934d444c6", "6626125ea124e200bee93ac3"]);
  const [userData, setUserData] = useState([]);
  const [authToken, setAuthToken] = useState(""); // Use authToken instead of token
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("auth-token");
        setAuthToken(token); // Set authToken state with the retrieved token

        const promises = userIds.map(async (id) => {
          const inquiriesResponse = await axios.get(
            `${apiUrl}/inquiry/get-inquri/${id}`,
            {
              headers: {
                "Content-Type": "application/json",
                "auth-token": token, // Use token here
              },
            }
          );
          const admissionsResponse = await axios.get(
            `${apiUrl}/student/get-student/${id}`,
            {
              headers: {
                "Content-Type": "application/json",
                "auth-token": token, // Use token here
              },
            }
          );

          return {
            userId: id,
            inquiries: inquiriesResponse.data,
            admissions: admissionsResponse.data,
          };
        });

        const data = await Promise.all(promises);
        setUserData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUserData();
  }, [authToken]); // Include authToken in dependency array

  return (
    <>
      {userData.map((user) => (
        <div key={user.userId}>
          <h2>User ID: {user.userId}</h2>
          <h3>Inquiries:</h3>
          <ul>
            {user.inquiries.map((inquiry) => (
              <li key={inquiry.id}>{inquiry.text}</li>
            ))}
          </ul>
          <h3>Admissions:</h3>
          <ul>
            {user.admissions.map((admission) => (
              <li key={admission.id}>{admission.text}</li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

export default Inquri_AdmissionCard;
