import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const CreateOthRecptive = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    studentName: "",
    receptiType: "",
    amount: "",
    paymentMethod: "",
    referenceId: "",
    cource: "",
    date: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const authToken = localStorage.getItem("auth-token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/other/post-otherreceptiv`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      console.log("Form data submitted successfully:", response.data);
      navigate("/get-other-receptiv");
    } catch (error) {
      console.error("Error submitting form data:", error.message);
    }
  };
  // const getTodaysDate = () => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = String(today.getMonth() + 1).padStart(2, "0");
  //   const day = String(today.getDate()).padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };
  return (
    <>
      <div
        className="col-sm-12 mb-3 mb-sm-0"
        style={{ margin: "auto", width: "96%", marginTop: "30px" }}
      >
        <div className="card w-100">
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="card-title" style={{ fontSize: "25px" }}>
              Create Other Receipt
            </h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon
                style={{ fontSize: "15px", marginLeft: "13px" }}
              />{" "}
              OTH Receipt
            </h6>
          </div>
        </div>
      </div>

      <div
        className="maincontainer-facliy"
        style={{
          padding: "22px",
          backgroundColor: "white",
          margin: "20px",
          borderRadius: "7px",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="studentName"
                label=" Student Name"
                value={formData.studentName}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: "15px" }}>
              <FormControl fullWidth>
                <InputLabel id="payment-method-label">
                  Invoices Types
                </InputLabel>
                <Select
                  labelId="payment-method-label"
                  name="receptiType"
                  value={formData.receptiType}
                  onChange={handleChange}
                >
                  <MenuItem value="Registration">Registration</MenuItem>
                  <MenuItem value="Booking">Booking</MenuItem>
                  <MenuItem value="Installment">Installment</MenuItem>
                  <MenuItem value="Admission">Admission</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="amount"
                label="Amount"
                type="number"
                value={formData.amount}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: "15px" }}>
              <FormControl fullWidth>
                <InputLabel id="payment-method-label">
                  Payment Method
                </InputLabel>
                <Select
                  labelId="payment-method-label"
                  name="paymentMethod"
                  value={formData.paymentMethod}
                  onChange={handleChange}
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Card">Card</MenuItem>
                  <MenuItem value="Online">Online</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="referenceId"
                label="Transaction ID"
                value={formData.referenceId}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="cource"
                label=" Course Name"
                value={formData.cource}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6} alignItems="flex-start">
              <TextField
                name="date"
                label="Date"
                type="date"
                value={formData.date}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default CreateOthRecptive;
