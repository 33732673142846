import React, { useEffect, useState } from "react";
import "./home.css";
import { Layout, Button, DatePicker, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  faCartPlus,
  faRocket,
  faRefresh,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../lodingpage/LoadingPage";
const { Header, Sider, Content } = Layout;
const { RangePicker } = DatePicker;

const OrderCard = ({ bgColor, icon, totalOrders, completedOrders, text }) => {
  return (
    <div className={`col-md-4 col-xl-3`}>
      <div className={`card bg-${bgColor} order-card`}>
        <div className="card-block">
          <h6 className="m-b-20" style={{ fontSize: "21px" }}>
            {text}
          </h6>
          <div className="text-right">
            <h2>
              <FontAwesomeIcon icon={icon} className="f-left" />
            </h2>
            <h2>
              <span>{totalOrders}</span>
            </h2>
          </div>
          <p className="m-b-0">
            {/* Completed Orders<span className="f-right">{completedOrders}</span> */}
          </p>
        </div>
      </div>
    </div>
  );
};

const HomePage = () => {
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = localStorage.getItem("auth-token");
  const [loading, setLoading] = useState(true);
  const [inquiryData, setInquiryData] = useState(null);
  const [othinvoice, setOthinvoice] = useState(null);
  const [admissionData, setAdmissionData] = useState({ totalDownPayment: 0 });
  const [invoiceData, setInvoiceData] = useState({ totalAmount: 0 });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDataAndSetLoading = async () => {
      try {
        // Fetch user data first
        const userResponse = await axios.post(
          `${apiUrl}/auth/getuser`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );
        setId(userResponse.data._id);
  
        // Then fetch other data using the obtained user id
        const [
          othinvoiceResponse,
          inquiryDataResponse,
          admissionDataResponse,
          invoiceDataResponse
        ] = await Promise.all([
          axios.get(`${apiUrl}/other/total-other-receptives/${userResponse.data._id}`, {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }),
          axios.get(`${apiUrl}/inquiry/get-inquri`, {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }),
          axios.get(`${apiUrl}/student/get-student`, {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }),
          axios.get(`${apiUrl}/invoice/get-invoice/total-amount`, {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          })
        ]);
  
        setOthinvoice(othinvoiceResponse.data);
        setInquiryData(inquiryDataResponse.data);
        setAdmissionData(admissionDataResponse.data);
        setInvoiceData(invoiceDataResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error); // Log the error
        setError(error.message);
        setLoading(false);
      }
    };
  
    fetchDataAndSetLoading();
  }, [apiUrl, authToken]);
  

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!inquiryData || !admissionData || !invoiceData) {
        // Navigate to home page if data fetching fails after 10 seconds
        navigate("/");
      }
    }, 6000);

    return () => clearTimeout(timeoutId); // Clear timeout on component unmount
  }, [inquiryData, admissionData, invoiceData, navigate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  // Calculate total amount
  const total = Math.round(invoiceData.totalAmount);

  return (
    <>
      <div className="container" style={{ marginTop: "20px" }}>
        {" "}
        <div
          className="col-sm-12 mb-3 mb-sm-0"
          style={{ margin: "auto", width: "96%" }}
        >
          <div className="card w-100">
            <div
              className="card-body"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h4 className="card-title" style={{ fontSize: "25px" }}>
                Centerl Dasboard
              </h4>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "blue", color: "white" }}
              >
                App Store
              </Button>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: "20px" }}>
          <div
            className="col-sm-12 mb-3 mb-sm-0"
            style={{ margin: "auto", width: "98%", marginTop: "20px" }}
          >
            <div className="card w-100">
              <div className="card-body">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Date Range
                    <RangePicker style={{ marginLeft: "20px" }} />
                  </div>
                  <div>
                    <Button type="primary" style={{ backgroundColor: "blue" }}>
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Layout>
            <Content
              style={{
                margin: "24px 16px",
                padding: 24,
                minHeight: 280,
                backgroundColor: "#d9d9d9",
                borderRadius: "10px",
              }}
            >
              <div className="container">
                <div className="row">
                  {inquiryData && (
                    <OrderCard
                      bgColor="c-blue"
                      text="Total Inquiry"
                      icon={faCartPlus}
                      totalOrders={inquiryData.totalCount}
                      completedOrders={inquiryData.completed}
                    />
                  )}
                  {admissionData && (
                    <OrderCard
                      bgColor="c-green"
                      icon={faRocket}
                      text="Total Admission"
                      totalOrders={admissionData.countTotal}
                      completedOrders={admissionData.completed}
                    />
                  )}
                  {othinvoice && (
                    <OrderCard
                      bgColor="c-yellow"
                      icon={faRefresh}
                      text="OTH Collection"
                      totalOrders={othinvoice.totalAmount}
                      completedOrders={othinvoice.completed}
                    />
                  )}
                  <OrderCard
                    bgColor="c-pink"
                    icon={faCreditCard}
                    text="Install Collection"
                    totalOrders={total}
                    completedOrders={351}
                  />
                </div>
              </div>
            </Content>
          </Layout>
        </div>
      </div>
    </>
  );
};

export default HomePage;
