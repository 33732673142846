import React from "react";
import Navbar from "../component/navbar/Navbar";
import CreateLab from "../component/Labpage/CreateLab";

const CreatedLab = () => {
  return (
    <>
      <Navbar>
        <CreateLab></CreateLab>
      </Navbar>
    </>
  );
};

export default CreatedLab;
