import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import CardComponent from "../admincards/AdminDasCard";
import Inquri_AdmissionCard from "../admincards/Inquri_AdmissionCard";

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2em;
  font-family: "Open Sans", sans-serif;
`;

const Card = styled.a`
  flex: 0 0 calc(24% - 1%);
  margin: 0.5%; /* 1% margin on both sides */
  display: flex;
  flex-direction: column;
  min-height: 16.75em;
  overflow: hidden;
  border-radius: 0.5em;
  text-decoration: none;
  background: white;
  padding: 2.75em 2.5em;
  box-shadow: 0 1.5em 2.5em -0.5em rgba(0, 0, 0, 0.1);
  transition: transform 0.45s ease, background 0.45s ease;

  &:hover {
    background: #753bbd;
    color: #ffffff;
    transform: scale(1.02);
  }

  @media (max-width: 768px) {
    flex: 0 0 calc(100% - 1%);
    margin: 0.5%; /* 1% margin on both sides */
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.primaryText};
  font-size: 2.5em;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 0.5em;
  margin: 0 0 0.142857143em;
  border-bottom: 2px solid ${({ theme }) => theme.primaryColor};
  transition: color 0.45s ease, border 0.45s ease;
`;

const Subtitle = styled.h4`
  color: ${({ theme }) => theme.secondaryText};
  text-transform: uppercase;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  margin: 0 0 1.777777778em;
  transition: color 0.45s ease;
`;

const Page = () => {
  const [cardData, setCardData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = localStorage.getItem("auth-token");

  useEffect(() => {
    if (!dataFetched) {
      const fetchWithToken = (url, method = 'GET', data = {}) => {
        return axios({
          method: method,
          url: url,
          headers: {
            "auth-token": authToken,
          },
          data: data
        });
      };

      // Fetch data from APIs here
      Promise.all([
        fetchWithToken(`${apiUrl}/auth/getuser`, 'POST'),
        fetchWithToken(`${apiUrl}/admin-dashboard/get-inquiry`),
        fetchWithToken(`${apiUrl}/admin-dashboard/get-alladmission`)
      ])
      .then(([userData, inquiryData, admissionData]) => {
        setCardData([
          { number: userData.data.usersCount, title: "Total User's" },
          { number: inquiryData.data.inquiryLength, title: "Total Inquiry" },
          { number: admissionData.data.allAdmissionLenth, title: "Total Admissions" }
        ]);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setDataFetched(true);
      });
    }
  }, [authToken, dataFetched]);

  return (
    <>
      <Container>
        {cardData.map(({ number, title }) => (
          <Card href="#" key={title}>
            <Title>{number}</Title>
            <Subtitle>{title}</Subtitle>
          </Card>
        ))}
      </Container>

      <CardComponent />
      <Inquri_AdmissionCard/>
    </>
  );
};

export default Page;
