import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  DashboardOutlined,
  UsergroupDeleteOutlined,
  ZoomInOutlined,
  PrinterOutlined,
  SolutionOutlined,
  ReadOutlined,
  FundOutlined,
  ScheduleOutlined,
  UserAddOutlined,
  ProductOutlined,
  FundProjectionScreenOutlined,
  DesktopOutlined,
  SmileOutlined,
  IssuesCloseOutlined,
  VerticalAlignBottomOutlined,
  ExportOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const { Header, Sider, Content } = Layout;

const Navbar = ({ children }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const fetchUserData = async () => {
      const authToken = localStorage.getItem("auth-token");

      if (authToken) {
        try {
          const response = await axios.post(
            `${apiUrl}/auth/getuser`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                "auth-token": authToken,
              },
            }
          );
          // Handle successful response
          setUserName(response.data.name);  
        } catch (error) {
          // Handle error
          if (error.response && error.response.status === 401) {
            console.error(
              "Unauthorized: Please authenticate using a valid token."
            );
            // Handle authentication error (e.g., redirect to login page)
          } else {
            console.error("Error fetching user data:", error);
          }
        } finally {
          // Set loading state to false
          setLoading(false);
        }
      }
    };

    fetchUserData(); // Call the fetchUserData function
  }, []); // Empty dependency array means this useEffect will only run once, similar to componentDidMount

  const handleLogout = () => {
    // Clear authentication token from local storage
    localStorage.removeItem("auth-token");
    // Redirect to home page
    navigate("/");
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const items = [
    {
      key: "1",
      icon: <DashboardOutlined />,
      label: "ERP Dasboard",
      link: "/home",
    },
    {
      key: "3",
      icon: <ZoomInOutlined />,
      label: "Inquriy",
      link: "/inquri-data",
    },
    {
      key: "2",
      icon: <UsergroupDeleteOutlined />,
      label: "Admission",
      link: "/admissiondata",
    },
   
    {
      key: "4",
      icon: <PrinterOutlined />,
      label: "Receipt",
      link: "/recepit-data",
    },
    {
      key: "17",
      icon: <PrinterOutlined />,
      label: "Other-Receipt",
      link: "/get-other-receptiv",
    },
    {
      key: "17",
      icon: <CalculatorOutlined />,
      label: "Month By Report",
      link: "/monthly-pay",
    },
    {
      key: "5",
      icon: <SolutionOutlined />,
      label: "Exam Hall Tikat",
      link: "/eaxmtickt-hall",
    },
    {
      key: "6",
      icon: <ReadOutlined />,
      label: "LMS Recepit",
      link: "/create-lms",
    },
    {
      key: "7",
      icon: <FundOutlined />,
      label: "Centerl Payment",
      link: "/central-payment",
    },
    {
      key: "8",
      icon: <UserAddOutlined />,
      label: "Faculity",
      link: "/faclity-profile",
    },
    {
      key: "9",
      icon: <ScheduleOutlined />,
      label: "Faculity Batches",
      link: "/find-batchs",
    },
    {
      key: "10",
      icon: <ProductOutlined />,
      label: "Branch Material",
      link: "/create-branch-material",
    },
    {
      key: "11",
      icon: <FundProjectionScreenOutlined />,
      label: "HO Payment",
      link: "/ho-payment",
    },
    {
      key: "12",
      icon: <DesktopOutlined />,
      label: "Lab",
      link: "/find-lab",
    },
    {
      key: "13",
      icon: <SmileOutlined />,
      label: "Lab Faculity",
      link: "/lab-faculty",
    },
    {
      key: "14",
      icon: <IssuesCloseOutlined />,
      label: "Certificate Request",
      link: "/certificate-request",
    },
    {
      key: "15",
      icon: <VerticalAlignBottomOutlined />,
      label: "Certificate Download",
      link: "/certificate-download",
    },
    {
      key: "16",
      icon: <ExportOutlined />,
      label: "Student Kit",
      link: "/student-kit",
    },
  ];

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1000,
          width: "100%",
          padding: "0 16px",
          background: colorBgContainer,
          display: "flex",
          justifyContent: "space-between", // Align items horizontally
          alignItems: "center", // Align items vertically
        }}
      >
        {/* First Part: Logo and Collapsed Button */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="demo-logo-vertical" style={{ width: "15%" }}>
            <img
              src={require("../../assest/New-logo-MITCA.webp")}
              alt="logo"
              style={{ marginRight: 16, width: "100%" }}
            />
          </div>
          <Button
            type="text"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined style={{ fontSize: "22px" }} />
              ) : (
                <MenuFoldOutlined style={{ fontSize: "22px" }} />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "22px",
              width: 64,
              height: 64,
              backgroundColor: "white",
            }}
          />
          <h5 style={{ fontSize: "22px" }}>
           ERP By Miracle Infosoft
          </h5>
        </div>
        
        {/* Third Part: Center Name and Logout Button */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{
              borderRadius: "3px",
              backgroundColor: "#0d6efd",
              color: "white",
            }}
          >
            <p style={{ color: "white" }}>Center Name: {userName}</p>
          </Button>
          <LogoutOutlined
            style={{
              fontSize: "22px",
              width: 35,
              height: 35,
              cursor: "pointer",
              transform: "rotate(-88deg)",
              borderRadius: "50px",
              backgroundColor: "#8b8bc6",
              marginLeft: "20px",
              padding: "6px",
              color: "white",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
            onClick={handleLogout}
          />
        </div>
      </Header>

      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          marginTop: 64,
          flexDirection: "column",
        }}
      >
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            overflowY: "auto",
            position: "fixed",
            height: "calc(100vh - 64px)",
            left: 0,
            top: 64,
            zIndex: 999,
          }}
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[location.pathname]}
            style={{ height: "100%", borderRight: 0 }}
          >
            {items.map((item) => (
              <Menu.Item key={item.link} icon={item.icon}>
                <Link to={item.link}>{item.label}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>

        {children}
      </Layout>
    </Layout>
  );
};

export default Navbar;
