import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import moment from "moment";

const BatchPage = () => {
  const authToken = localStorage.getItem("auth-token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBatchId, setSelectedBatchId] = useState(null); // New state to track selected batch
  const [batches, setBatches] = useState([]);

  const handleButtonClick = (event, batchId) => {
    setAnchorEl(event.currentTarget);
    setSelectedBatchId(batchId); // Set the selected batch id
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedBatchId(null); // Reset selected batch id when menu closes
  };

  const handleEdit = (id) => {
    console.log("Batch id", id)
    handleMenuClose();
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/batch/batches`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      setBatches(response.data.batches);
      console.log(response.data.batches);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [authToken]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/batch/batches/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      handleMenuClose();
      fetchData();
    } catch (error) {
      console.error("Error deleting batch:", error);
    }
  };

  const buttonStyle = {
    width: "200px",
    margin: "auto",
    display: "block",
  };

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <Link to="/create-batchs">
          <Button variant="contained" style={buttonStyle}>
            Add Batch
          </Button>
        </Link>
      </div>
      <h2 style={{ textAlign: "center", marginTop: "10px" }}>
        Facility Batches
      </h2>
      <Grid container spacing={2}>
        {batches.map((batch, index) => (
          <Grid item xs={6} key={index}>
            <Card
              variant="outlined"
              style={{ marginBottom: "20px", margin: "10px", display: "flex" }}
            >
              <CardContent style={{ width: "100%" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5" component="h2" gutterBottom>
                        {batch.facilityName}
                      </Typography>
                      <div
                        style={{
                          width: "5%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* Set width to 5% for action button */}
                        <Button
                          aria-controls={`batch-actions-menu-${index}`} // Unique id for each menu
                          aria-haspopup="true"
                          onClick={(event) => handleButtonClick(event, batch._id)} // Pass batch id
                          endIcon={<MoreVertIcon />}
                        ></Button>
                        <Menu
                          id={`batch-actions-menu-${index}`} // Unique id for each menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl && selectedBatchId === batch._id)} // Open only if selectedBatchId matches
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={() => handleEdit(batch._id)}>
                            Edit
                          </MenuItem>
                          <MenuItem onClick={() => handleDelete(batch._id)}>
                            Delete
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    <Typography variant="body1">
                      Batch Start Date:
                      {moment(batch.startDate).format("DD/MM/YYYY")}
                    </Typography>
                    <Typography variant="body1">
                      Batch End Date:
                      {moment(batch.endDate).format("DD/MM/YYYY")}
                    </Typography>
                    <Typography variant="body1">
                      Batch Time: {batch.batchTime}
                    </Typography>
                    <Typography variant="body1">
                      Topic Name: {batch.batchTitle}
                    </Typography>
                    <Typography variant="body1">
                      Students Names:
                      {batch.studentName.map((name, index) => (
                        <span key={index} style={{ marginLeft: "4px" }}>
                          {index + 1}. {name}
                        </span>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default BatchPage;
