import React from 'react'
import NavbarAdmin from '../component/adminDasboard/NavbarAdmin'
import AdminAdmissionData from '../component/adminDasboard/GetAdmission'

const AdminAdmission = () => {
  return (
  <NavbarAdmin>
    <AdminAdmissionData>
        
    </AdminAdmissionData>
  </NavbarAdmin>
  )
}

export default AdminAdmission