import React from "react";
import Navbar from "../component/navbar/Navbar";
import RecepitPage from "../component/recepitpage/RecepitPage";

const CreateRecepit = () => {
  return (
    <Navbar>
      <RecepitPage></RecepitPage>
    </Navbar>
  );
};

export default CreateRecepit;
