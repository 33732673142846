import React, { useEffect, useState } from "react";
import "./student.css";
import { TextField, Grid } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const SingleStudent = () => {
  const authToken = localStorage.getItem("auth-token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [studentData, setStudentData] = useState(null);
  const [additionalData, setAdditionalData] = useState([]); // State for additional data
  const { id } = useParams();

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/student/get-student/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );
        setStudentData(response.data.admission); // Assuming admission contains student data
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    fetchStudentData();
  }, [id, apiUrl, authToken]);

  useEffect(() => {
    const fetchAdditionalData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/invoice/invoice/${id}`, {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        });
        setAdditionalData(response.data); // Assuming response.data contains additional data
      } catch (error) {
        console.error("Error fetching additional data:", error);
      }
    };

    // Check if studentData is available before making the second API call
    if (studentData) {
      fetchAdditionalData();
    }
  }, [id, apiUrl, authToken, studentData]);

  useEffect(() => {
    // Log studentData and additionalData only when they change
    console.log(studentData);
    console.log(additionalData);
  }, [studentData, additionalData]);

  return (
    <>
      <div
        className="col-sm-12 mb-3 mb-sm-0"
        style={{ margin: "auto", width: "88%", marginTop: "30px" }}
      >
        <div className="card w-100">
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 className="card-title" style={{ fontSize: "25px" }}>
              {" "}
              Student Profile
            </h4>
            <h6 className="admissionf1">
              <Link to="/dashboard">Home</Link>
              <ArrowBackIosIcon
                style={{ fontSize: "15px", marginLeft: "13px" }}
              />
              Profile
            </h6>
          </div>
        </div>
      </div>
      {studentData && (
        <div className="container" style={{ marginTop: "10px" }}>
          <div className="main-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="studentcard">
                  <div className="studentcard-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar6.png"
                        alt="Admin"
                        className="rounded-circle p-1 bg-primary"
                        style={{ width: "40%" }}
                      />
                      <div className="mt-3">
                        <h4>{studentData.name}</h4>
                        <p className="text-secondary mb-1">
                          {studentData.cource}
                        </p>
                        <p className="text-muted font-size-sm">
                          {studentData.residenceAddress}
                        </p>
                        <button className="btn btn-primary">Follow</button>
                        <button
                          className="btn btn-outline-primary"
                          style={{ marginLeft: "5px" }}
                        >
                          Message
                        </button>
                      </div>
                    </div>
                    <hr className="my-4" />
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-globe me-2 icon-inline"
                          >
                            <circle cx={12} cy={12} r={10} />
                            <line x1={2} y1={12} x2={22} y2={12} />
                            <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
                          </svg>
                          Website
                        </h6>
                        <span className="text-secondary">
                          https://bootdey.com
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-github me-2 icon-inline"
                          >
                            <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
                          </svg>
                          Github
                        </h6>
                        <span className="text-secondary">
                          {" "}
                          https://bootdey.com
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-twitter me-2 icon-inline text-info"
                          >
                            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                          </svg>
                          Twitter
                        </h6>
                        <span className="text-secondary">@bootdey</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-instagram me-2 icon-inline text-danger"
                          >
                            <rect
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={5}
                              ry={5}
                            />
                            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                          </svg>
                          Instagram
                        </h6>
                        <span className="text-secondary">bootdey</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-facebook me-2 icon-inline text-primary"
                          >
                            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                          </svg>
                          Facebook
                        </h6>
                        <span className="text-secondary">bootdey</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="studentcard">
                  <Grid
                    container
                    spacing={2}
                    style={{
                      padding: "20px",

                      marginTop: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Student ID "
                        name="receiptNumber"
                        value={studentData.studentid}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Form Number"
                        name="fromnumber"
                        value={studentData.fromnumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Name"
                        name="receiptNumber"
                        value={studentData.name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Mobile Number"
                        name="mobileNumber"
                        value={studentData.mobileNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Email ID"
                        name="email"
                        value={studentData.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Category"
                        name="category"
                        value={studentData.category}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Fater's Name"
                        name="fatherName"
                        value={studentData.fatherName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Parants Mobile Number"
                        name="parentsContact"
                        value={studentData.parentsContact}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Digree"
                        name="degree"
                        value={studentData.degree}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Course"
                        name="cource"
                        value={studentData.cource}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Duration"
                        name="durationCource"
                        value={studentData.durationCource}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Admission Date"
                        name="createdAt"
                        value={moment(studentData.createdAt).format(
                          "DD/MM/YYYY"
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Total Fees"
                        name="totalFees"
                        value={studentData.totalFees}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="After Placement Fees"
                        name="lmsFees"
                        value={studentData.lmsFees}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Other Fees"
                        name="otherFees"
                        value={studentData.otherFees}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Installment"
                        name="installment"
                        value={studentData.installment}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Installment Amount"
                        name="installmentAmount"
                        value={studentData.installmentAmount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="City"
                        name="city"
                        value={studentData.city}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Address"
                        name="permanentAddress"
                        value={studentData.permanentAddress}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Remarke"
                        name="remark"
                        value={studentData.remark}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Sr.No
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Invoice No.
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Student Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Payment Mode
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {additionalData.map((data, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                            <td className="px-6 py-4">{index+1}</td>
                            <td className="px-6 py-4">{data.invoiceNumber}</td>
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {data.studentName}
                          </th>
                         
                          <td className="px-6 py-4">{data.paymentMethod}</td>
                          <td className="px-6 py-4">
                            {moment(data.date).format("DD/MM/YY")}
                          </td>
                          <td className="px-6 py-4">{data.amount}/-</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleStudent;